import React,{Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../../../presentational/table-pagination-actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AuthHelper from '../../../../libraries/auth-helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import { withRouter, Link } from 'react-router-dom';
import Api from '../../../../libraries/api';
import languange from '../../../../libraries/label-lang';
import {bindActionCreators} from 'redux';
import {RootActions} from '../../../../shared/root-action';
import {connect} from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { SearchInput } from "../../styling";


class ManageUsers extends Component {


    constructor(props) {
        let lang = AuthHelper.getLang();

        super(props);

        this.state = {
            loading: false,
            loadingButton: false,

            errors: [],

            showDialog: false,
            idDelete: '',
            currDelete: '',

            orderBy: '',
            sortedBy: '',
            searchBy: '',
            keyword: '',

            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,

            role:'',

            user:'',
            showActivate: false,
            idChange: '',
            currChange: '',
            accessChange: '',
            listRules:{}

        };

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    componentDidMount() {
        document.title = 'CMS - Mater Kategori';
        this._loadData();

    };

    _loadData = _ => {
        const usersLogin = AuthHelper.getProfile();
        this.setState({
            loading: true,
            user: usersLogin,
            rows:[]
        });

        if(usersLogin && usersLogin.role && (usersLogin.role === 1 || usersLogin.role === 2)) {
            const { perPage, currentPage, keyword } = this.state;

            const params = {
                ...(perPage ? { size: Number(perPage) } : {}),
                ...(currentPage ? { page: Number(currentPage) } : {}),
                ...(keyword ? { search : keyword } : {}),
            };

            Api.get('/roles').then(res => {
                let dataRoles = {};
                let response = res.result.data;

                response.map(v=>{
                    if(v.code){
                        dataRoles[v.code] = v.name;
                    }
                    return v;
                })
                // Object.keys(res).map(item => {
                //     if(res[item] && res[item].code) {
                //         dataRoles.push(res[item]);
                //     }
                // });
                this.setState({
                    listRules: dataRoles,
                    loading: false,
                })
            }).catch((err) => {
                this.setState({
                    loading: false,
                });
                this.showMessage(false, err && err.message ? err.message : 'address not found!');
            });

            Api.post('/users',params).then(resp => {
                let response = resp?.result ?? {};
                this.setState({
                    rows: response?.data ?? [],
                    loading: false,

                    // paginations
                    perPage: response?.pagination && response.pagination.size ? response.pagination.size : this.state.perPage,
                    currentPage: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPage,
                    currentPageTable: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPageTable,
                    total: response?.pagination && response?.pagination.totalItems ? response?.pagination.totalItems : this.state.total,
                });
                this.setState({ loading: false, });
            })
            .catch(e => {
                this.showMessage(false, e.message);
                this.setState({ loading: false, });
            })
        }
        else {
            this.setState({ loading: false, });
            this.showMessage(false, 'You can`t be access this pages!');
            // history.push('/');
        }



    }

    handleClose = () => {
        this.setState({
            showDialog: false,
            showActivate: false
        })
    };

    handleDelete = () => {

        if (this.state.idDelete) {
            let lang = {};
            if (this.props.toggle_set_lang === 'indo') {
                lang = languange.id;
            } else {
                lang = languange.en;
            }
            const text = lang;
            Api.delete('/users/' + this.state.idDelete, '', false).then(resp => {

                this.setState({
                        showDialog: false,
                    }
                );

                this.showMessage(true, text.label_data_success+text.label_delete);
                this._loadData()

            }).catch(err => {

                this.setState({
                        showDialog: false
                    }
                );

                this.showMessage(true, err.message);
                this._loadData()
            });
        }
    };

    handleChange = () => {
        if (this.state.idChange) {
            let lang = {};
            if (this.props.toggle_set_lang === 'indo') {
                lang = languange.id;
            } else {
                lang = languange.en;
            }
            const text = lang;

            Api.put('/users/' + this.state.idChange, {access:!this.state.accessChange}).then(resp => {

                this.setState({
                        showActivate: false,
                    }
                );

                this.showMessage(true, text.label_data_success+(this.state.accessChange ? text.label_deactivation : text.label_activation));
                this._loadData()

            }).catch(err => {

                this.setState({
                        showActivate: false
                    }
                );

                this.showMessage(true, err.message);
                this._loadData()
            });
        }
    };

    handleGo = (link) => {
        history.push(link);
    };

    handleOpen = (row) => {
        this.setState({
            showActivate :true,
            idChange: row.id,
            currChange: row.name,
            accessChange: row.access
        })
    };

    handleOpenDelete = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.name,
        })
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSort = (val) => {

        let sortedBy = this.state.sortedBy;

        if (val !== this.state.orderBy) {
            sortedBy = 'asc';
        } else {
            if (sortedBy && sortedBy === 'asc') {
                sortedBy = 'desc';
            } else {
                sortedBy = 'asc';
            }
        }

        this.setState({
            orderBy: val,
            sortedBy: sortedBy,
            loading: true
        }, () => {
            this._loadData()
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage + 1,
            currentPageTable: newPage + 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 10),
            currentPage: 1,
            loading: true,
        }, () => {
            this._loadData()
        });
    };


    __handleSearchEvent = (data,props)=>{
        this.setState({
            [props]:data ? data : ''
        },()=> {
            this._loadData();
        });
    };


    render() {
        let lang = {};
        if (this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        } else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.label_manage_users}</li>
                    </ol>
                </nav>
                <h2 className='text-uppercase'>{text.label_manage_users}
                    <Button
                        variant='contained'
                        color='primary'
                        className='float-right mb-3'
                        onClick={() => this.handleGo('/manage_users/add')}
                    >
                        {text.button_add} {text.label_account}
                    </Button>
                </h2>

                <div className='d-inline-flex align-items-center w-100 justify-content-between my-2'>
                    <SearchInput
                        placeholder={text.label_browse + ' ' + text.label_account}
                        value={this.state.keyword}
                        onSubmit={(x) => this.__handleSearchEvent(x, 'keyword')}
                        onKeyPress={(x) => this.__handleSearchEvent(x, 'keyword')}
                        onKeyUp={(x) => this.__handleSearchEvent(x, 'keyword')}
                    />
                </div>

                <div className='clearfix'> </div>
                <div className='table-wrapper'>
                    <Table className='table-list' size='small' >
                        <TableHead>
                            <TableRow>
                                <TableCell className='clickable' onClick={() => this.handleSort('nip')} >{text.label_nip}
                                    {this.state.orderBy === 'nip' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('name')} >{text.label_name}
                                    {this.state.orderBy === 'name' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('address')} >{text.label_address}
                                    {this.state.orderBy === 'address' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('role')} >{text.label_rules}
                                    {this.state.orderBy === 'role' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell className='clickable' onClick={() => this.handleSort('access')} >{text.label_status}
                                    {this.state.orderBy === 'access' && (
                                        <span className='icon-sort'>{
                                            this.state.sortedBy === 'asc' ? (
                                                <i className='fas fa-sort-up'> </i>
                                            ) : (
                                                <i className='fas fa-sort-down'> </i>
                                            )
                                        }</span>
                                    )}
                                </TableCell>
                                <TableCell>{text.label_action}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.loading ? (
                                <TableRow>
                                    <TableCell colSpan={6} align='center' className='py-5'>
                                        <CircularProgress color='primary' />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                this.state.rows && this.state.rows.length === 0 ? (
                                    <TableRow style={{ height: 33 * this.state.perPage }}>
                                        <TableCell colSpan={6} style={{ textAlign: 'center' }}>{text.label_no}</TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.map((row, i) => (
                                        <TableRow
                                            // key={i}
                                            style={{ cursor: 'pointer' }}
                                            onDoubleClick={_ =>{
                                            // onClick={_ => {
                                                history.push({
                                                    pathname: 'manage_users/' + row.id,
                                                    state: row
                                                })
                                            }}
                                            key={row.id}
                                            selected={row.selected}>
                                            <TableCell>{row.nip ? row.nip : '-'}</TableCell>
                                            <TableCell>{row.name ? row.name : '-'}</TableCell>
                                            <TableCell>{row.address ? row.address : '-'}</TableCell>
                                            <TableCell>{row.role && this.state.listRules ?
                                                this.state.listRules[row.role]
                                                : '-'
                                            }</TableCell>
                                            <TableCell>{row.access ?
                                                <label className='w-75 badge badge-success' onClick={() => this.handleOpen(row)}>Aktif</label> :
                                                <label className='w-75 badge badge-dark' onClick={() => this.handleOpen(row)} >Not Aktif</label>
                                            }</TableCell>
                                            <TableCell>
                                                <button className='btn-icon' onClick={() => this.handleOpenDelete(row)}>
                                                    <i className='fas fa-trash-alt'> </i>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )
                            )}

                        </TableBody>
                    </Table>
                </div>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component='div'
                    className='responsive'
                    count={this.state.total}
                    rowsPerPage={this.state.perPage}
                    page={this.state.currentPageTable}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{text.label_delete} member "{this.state.currDelete}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showActivate}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{this.state.accessChange ? text.label_deactivation : text.label_activation} member "{this.state.currChange}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions className='text-center'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleChange}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageUsers));
