import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import {toast, ToastContainer} from 'react-toastify';
import {bindActionCreators} from 'redux';
import Api from '../../../../libraries/api';
import languange from "../../../../libraries/label-lang";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AuthHelper from "../../../../libraries/auth-helper";
import SimpleReactValidator from "simple-react-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import {SearchInput} from "../../styling";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TablePaginationActions from "../../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import _ from "lodash";
import {MultipleUpload} from "../lib_questions/multipleUpload";
import {MultipleAnswers} from "../lib_questions/multipleAnswers";
import {MultipleQuestions} from "../lib_questions/multipleQuestions";

require('dotenv').config();

class CategoryDetail extends Component {
    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loading: false,
            loadingButton: false,
            showButton: false,

            errors: [],
            listCategory:[],
            listView:[
                {
                    name: '--- Subcategories ---',
                    id: ''
                },
                {
                    name: 'Table',
                    id: 'Table'
                },
                {
                    name: 'Multiple Choice',
                    id: 'Multiple Choice'
                },
                {
                    name: 'Image',
                    id: 'Image'
                },
                {
                    name: 'Kraepelin',
                    id: 'Kraepelin'
                },
            ],
            listSubcategory:[],
            _tempListSubcategory:[{
                name: '--- Subcategories ---',
                id: ''
            }],

            // params detail theory
            id: '',
            name: '',
            categoryId:'',
            subcategoryId:'',
            descriptions: '',
            duration:'',
            active:false,
            view:'',

            disableUpdate: true,


            // params table question
            // --tables
            rows: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            orderBy: '',
            sortedBy: '',
            searchBy: '',
            keyword: '',
            showDialog: false,
            idDelete: '',
            currDelete: '',
            showActivate :false,
            idChange: '',
            currChange: '',
            dataChange:'',
            accessChange: '',

            // addQuestions: {
            theoryId: '',
            questions: '',
            __quest: [],
            images: '',
            code: 0,
            examples: false,
            answers: '',
            listAnswers: [],
            type: '', // radio, checkbox, input
            answer: '',
            // },
            countQuestion:0,
            sumQuestion:0,
            listType : [
                { id: '', name:'--- Type Answer ---' },
                { id: 'radio', name:'Multiple Choice' },
                { id: 'checkbox', name:'Check Box' },
                { id: 'input', name:'Text Input' }
            ], // radio, checkbox, input

            imageFile: {},
            imagePut: {}

        }

        // this.handleUpload = this.handleUpload.bind(this);
        // this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        document.title = 'PLPT - Detail';
        const id = Number(this.props.match.params.id);
        const dataS = this.props?.location?.state ?? '';

        this.setState({
            loading: true,
            loadingButton: true,
            id: id,
        });

        if(dataS){
            let setData = {};
            Object.keys(dataS).map(k =>{
                setData[k]= dataS[k] ?? '';
                return k;
            });
            this.setState({
                ...setData,
                // loadingButton: false
            },()=>{
                // call data list questions
                this.__fetchData();
            });
        }
        else {
            this._loadData(id);
        }
    };

    _loadData(id){
        Api.get('/theories/detail/'+id).then(resp => {
            let response = resp.result.data;
            let setData = {};

            Object.keys(response).map(k =>{
                setData[k]= response[k];
                return k;
            });
            this.setState({
                ...setData
            },()=>{
                // call data list questions
                this.__fetchData();
            });
        }).catch(err => {
            this.setState({
                loading: false,
                loadingButton: false,
            });
            this.showMessage(false, err.message);
        });
    }

    __fetchData(){
        const { perPage, currentPage, keyword , id:theoryId} = this.state
        const params = {
            ...(perPage ? { size: Number(perPage) } : {}),
            ...(currentPage ? { page: Number(currentPage) } : {}),
            ...(keyword ? { search : keyword } : {}),
            ...(theoryId ? { theoryId : theoryId } : {}),
        };
        Api.post('/questions_detail',params).then(resp => {
            let response = resp?.result ?? {};
            if(response.data.length){
                response.data.map(v=>{
                    if(v.question){
                        // v.question = v.question.split('\n').join('<br/>');
                        v.question = JSON.parse(v.question);
                        // if(v.question.length) {
                        //     v.question.map(nv=>{
                        //         nv.question = nv.question.split('\n').join('<br/>');
                        //     })
                        // }
                    }
                    return v;
                })
            }
            this.setState({
                loading: false,
                loadingButton: false,
                rows: response?.data  ?? [],
                sumQuestion: response?.data ? response?.data.length : 0,
                countQuestion: response?.pagination?.totalItems ? response?.pagination?.totalItems : 0,
                // paginations
                perPage: response?.pagination && response.pagination.size ? response.pagination.size : this.state.perPage,
                currentPage: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPage,
                currentPageTable: response?.pagination && response?.pagination.currentPage ? response?.pagination.currentPage -1 : this.state.currentPageTable,
                total: response?.pagination && response?.pagination.totalItems ? response?.pagination.totalItems : this.state.total,
            },()=>{
            });

        }).catch(err => {
            this.setState({
                loading: false,
                loadingButton: false,
            });
            this.showMessage(false, err.message);
        });
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage + 1,
            currentPageTable: newPage + 1,
            loading: true,
            loadingButton: true,
        }, () => {
            this.__fetchData()
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 10),
            currentPage: 1,
            loading: true,
            loadingButton: true,
        }, () => {
            this.__fetchData()
        });
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        },()=>{
            if(prop === 'type'){
                // this.__reloadSubcategory(e.target.value);
                this.handleAdd(e.target.value);
            }
        });
    };

    handleClose = () => {
        this.setState({
            showDialog: false,
        })
    };

    handleDelete = () => {

        if (this.state.idDelete) {
            let lang = {};
            if (this.props.toggle_set_lang === 'indo') {
                lang = languange.id;
            } else {
                lang = languange.en;
            }
            const text = lang;
            Api.delete('/questions_detail/' + this.state.idDelete, '', false).then(resp => {

                this.setState({
                        loading: true,
                        showDialog: false,
                    }
                );

                this.showMessage(true, text.label_data_success+text.label_delete);
                this.__fetchData()

            }).catch(err => {

                this.setState({
                        loading: true,
                        showDialog: false
                    }
                );

                this.showMessage(true, err.message);
                this.__fetchData()
            });
        }
    };

    __reloadSubcategory(id){
        let _tmp = [{
            name: '--- Subcategories ---',
            id: ''
        }];
        this.state.listSubcategory.map(v=>{
            if(v.categoryId === id){
                _tmp.push(v);
            }
            return v;
        });
        this.setState({
            _tempListSubcategory: _tmp
        });
    }

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = (data,reload = false)=>{
      this.setState({
          theoryId: this.state.id,
          questions: '',
          images: '',
          code: this.state.countQuestion,// + 1,
          examples: false,
          answers: [],
          type: '', // radio, checkbox, input
          answer: '',
          disableUpdate: !this.state.disableUpdate,
          idChange: '',
          listAnswers: [],
          listQuestions: [],
          // addQuestions: add
      },()=>{
          if(reload){
              this.__fetchData();

          }
          else{
              this.handleAdd('question');
          }

      })
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleChecked = name => e => {
        this.setState({
            [name]: e.target.checked ? true : false,
        })
    };

    handleOpenDelete = (row) => {
        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.code +1,
        })
    };

    handleOpen = (row) => {
        let dataList = row.answers ? JSON.parse(row.answers) : [];
        let dataType = row?.type ?? '';
        let imgQuest = row?.images ? JSON.parse(row.images) : [];

        let dataQuest = row?.question ? (typeof(row.question) === 'string') ? JSON.parse(row.question) : row.question : [];

        let _imagePut = {};
        let _imageFile ={};

        if(dataList && dataList.length){
            dataList.map((v,kA)=>{
                if(v.images && v.images.length){
                    v.images.map(v1=>{
                        if(v1) {
                            if (_imagePut['_ansImg' + kA] && _imagePut['_ansImg' + kA].length) {
                                _imagePut['_ansImg' + kA].push(v1);
                                _imageFile['_ansImg' + kA].push({
                                    name: v1
                                });
                            } else {
                                _imagePut['_ansImg' + kA] = [v1];
                                _imageFile['_ansImg' + kA] = [{
                                    name: v1
                                }];
                            }
                        }
                        return v1;
                    })
                }
                return v;
            })
        }
        if(imgQuest && imgQuest.length){
            imgQuest.map(v=>{
                if(_imagePut['__imgQuest'+row.code + 1] && _imagePut['__imgQuest'+row.code + 1].length){
                    _imagePut['__imgQuest'+row.code + 1].push(1);
                    _imageFile['__imgQuest'+row.code + 1].push({
                        name:v
                    });
                }
                else{
                    _imagePut['__imgQuest'+row.code + 1] = [v];
                    _imageFile['__imgQuest'+row.code + 1] = [{
                        name:v
                    }];
                }
                return v;

            });
        }
        // if(dataQuest && dataQuest.length){
        //     dataQuest.map(v=>{
        //         if(_imagePut['__quest'+row.code + 1] && _imagePut['__quest'+row.code + 1].length){
        //             _imagePut['__quest'+row.code + 1].push(1);
        //             _imageFile['__quest'+row.code + 1].push({
        //                 name:v
        //             });
        //         }
        //         else{
        //             _imagePut['__quest'+row.code + 1] = [v];
        //             _imageFile['__quest'+row.code + 1] = [{
        //                 name:v
        //             }];
        //         }
        //
        //     });
        // }

        this.setState({
            // showActivate :true,
            idChange: row.id,
            // currChange: row.name,
            // dataChange: row,
            // accessChange: row.active,
            imagePut:_imagePut,
            imageFile:_imageFile,

            theoryId: row.theoryId,
            questions: dataQuest,//row['question'],
            images: row.images ? JSON.parse(row.images) : '',
            code: row.code,
            examples: row['example'],
            answers: dataList,
            listQuestions: dataQuest,
            listAnswers: dataList,
            type: dataType, // radio, checkbox, input
            answer: row.answer,//row.answer,
            disableUpdate: !this.state.disableUpdate,
        },()=>{
            if(dataList.length === 0 ) {//&& (dataType === 'radio' || dataType === 'checkbox')){
                this.handleAdd(dataType);
            }
            if(dataQuest.length === 0 ){

                this.handleAdd('question');
            }
        });
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let nextAnswer = [];

        if(this.state.listAnswers.length){// && (this.state.type === 'radio' || this.state.type === 'checkbox')){
            this.state.listAnswers.map((v,kA)=>{
                if(this.state.imagePut && this.state.imagePut['_ansImg'+kA]){
                    v.images = this.state.imagePut['_ansImg'+kA];
                }
                // if(this.state.imageFile && this.state.imageFile['_ansImg'+kA]){
                //     v.images = this.state.imageFile['_ansImg'+kA];
                // }
                if(v.label || v.answer) {
                    nextAnswer.push(v);
                }
                return v;
            });

            if(nextAnswer && nextAnswer.length) {
                nextAnswer.map((f,k)=>{
                    f.code = k;
                    return f;
                });
            }
        }

        // let nextQuest = [];

        // if(this.state.listQuestions.length){
        //     this.state.listQuestions.map((v,kA)=>{
        //         // if(this.state.imagePut && this.state.imagePut['_ansImg'+kA]){
        //         //     v.images = this.state.imagePut['_ansImg'+kA];
        //         // }
        //         if(v.label || v.answer) {
        //             nextAnswer.push(v);
        //         }
        //     });
        //
        //     if(nextAnswer && nextAnswer.length) {
        //         nextAnswer.map((f,k)=>{
        //             f.code = k;
        //         });
        //     }
        // }

        let questImg = [];

        if(this.state.imagePut && this.state.imagePut['__imgQuest'+this.state.code + 1]){
            questImg = this.state.imagePut['__imgQuest'+this.state.code + 1];
        }

        // if(this.state.imageFile && this.state.imageFile['__imgQuest'+this.state.code + 1]){
        //     questImg = this.state.imageFile['__imgQuest'+this.state.code + 1];
        // }

        let params = {
            // name: this.state.name,
            // categoryId: this.state.categoryId,
            // descriptions: this.state.descriptions,
            // subcategoryId: this.state.subcategoryId,
            // duration:  this.state.duration,
            // active:  this.state.active,
            // view:  this.state.view,
            theoryId: this.state.theoryId,
            question:  JSON.stringify(this.state.listQuestions),// this.state.questions,
            images:  JSON.stringify(questImg),//this.state.images,
            code:  this.state.code,
            example:  this.state.examples,
            answers:  JSON.stringify(nextAnswer),//this.state.listAnswers),
            type:  this.state.type, // radio, checkbox, input
            answer:  this.state.answer,
        };

        // this.setState({
        //     loading: true,
        //     loadingButton: false,
        // })
        //
        //
        // return false;

        if (this.state.idChange) {

            Api.put('/questions_detail/' + this.state.idChange, params).then(resp => {

                this.setState({
                        loading: true,
                        loadingButton: false,
                        disableUpdate: !this.state.disableUpdate,
                    }
                );

                this.showMessage(true, resp?.message ?? 'Update question successfully');
                this.__fetchData();
                this.setState({
                    imagePut:{},
                    imageFile:{},
                })

            }).catch(err => {

                let errors = {};
                Object.keys(err).map((item) => {
                    if (item) {
                        errors[item] = err[item][0] ? err[item][0] : err[item];
                    }
                    return item;
                });

                this.setState({
                        errors: errors ? errors : {},
                        loadingButton: false
                    }
                );

                this.showMessage(true, err?.message ?? 'Update question failed');
                this.__fetchData()
            });
        }

        else {
            // params.code = params.code -1;
            Api.post('/questions_detail/add', params).then(resp => {

                this.setState({
                    loading: true,
                    loadingButton: false,
                    // countQuestion: this.state.countQuestion++,
                    disableUpdate: !this.state.disableUpdate,

                }, () => {
                    this.__fetchData();
                });

                this.showMessage(true, resp?.message ?? 'Add question successfully');

                this.setState({
                    imagePut:{},
                    imageFile:{},
                })
                // this.handleGo('/master_theory');
            }).catch(err => {

                let errors = {};
                Object.keys(err).map((item) => {
                    if (item) {
                        errors[item] = err[item][0] ? err[item][0] : err[item];
                    }
                    return item;
                });

                this.setState({
                        errors: errors ? errors : {},
                        loadingButton: false
                    }
                );

                this.showMessage(false, err?.message ?? 'Add question failed');

            });
        }


    };

    // handle multiple adding data

    handleChangeAnswer = (e, prop, idx, position = false) =>{
        if(position) {
            let data = this.state.listQuestions;

            // return false;
            // if(data.length){
                data[idx][prop] = e.target.value;
            // }
            // else{
            //     data = [{
            //         code: this.state.listQuestions.length,
            //         question: e.target.value
            //     }];
            // }

            _.forEach(data, function (v, k) {
                if (k === idx) {
                    v[prop] = e.target.value;
                }
                v.code = k;
            });
            this.setState({

                listQuestions: data

            }, () => {
            });
        }
        else {
            let data = this.state.listAnswers ;

            // return false;
            _.forEach(data, function (v, k) {
                if (k === idx) {
                    v[prop] = e.target.value;
                }
                v.code = k;
            });
            this.setState({

                listAnswers: data

            }, () => {
            });
        }
    };

    handleAdd = (e) => {

        // if(e === 'radio' || e === 'checkbox'){
        if(e === 'question'){
            let exp = {
                code : this.state.listQuestions.length,
                question : ''
            };
            let nextQuest = []
            if(this.state.listQuestions.length){
                _.map(this.state.listQuestions,(v,k)=>{
                    nextQuest.psuh(v);
                });
            }
            nextQuest.push(exp);
            this.setState({
                listQuestions: nextQuest,
                showButton: true,
            });

        }
        else {
            let exp = [{
                code : this.state.listAnswers.length,
                label : '',
                answer : '',
                images:[]
            }];
            this.setState({
                listAnswers: exp,
                showButton: true,
            });

        }

    };

    handlePlus = (id, options = null) => {
        if(options === 'question') {
            let data = this.state.listQuestions;
            data.push({
                code: id + 1,
                question: ''
            });

            this.setState({
                listQuestions: data,
            });
        }
        else {
            let data = this.state.listAnswers;
            data.push({
                code: id + 1,
                label: '',
                answer: '',
                images: []
            });

            this.setState({
                listAnswers: data,
            });
        }
    };

    handleMinus = (id, options = null) => {
        if(options === 'question') {
            let data = this.state.listQuestions;

            let to_delete_list = id + 1;

            if (to_delete_list === 1) {
                this.showMessage(false, 'This data can`t be deleted!');
                return;
            }

            data.splice(to_delete_list - 1, 1);

            this.setState({
                listQuestions: data,
            });

            this.handleReplaceList('question');
        }
        else {
            let data = this.state.listAnswers;

            let to_delete_list = id + 1;

            if (to_delete_list === 1) {
                this.showMessage(false, 'This data can`t be deleted!');
                return;
            }

            // TODO create event handle delete images on registered list answer

            data.splice(to_delete_list - 1, 1);

            this.setState({
                listAnswers: data,
            });

            this.handleReplaceList();
        }
    };

    handleReplaceList = (options= null) =>{
        if(options === 'question') {
            let n = [];
            this.state.listQuestions.map(f => {
                n.push(f);
                return f;
            });
            if (n && n.length) {
                n.map((v, k) => {
                    v.code = k;
                    return v;
                });

                this.setState({
                    listQuestions: n
                });
            }
        }
        else {
            let n = [];
            this.state.listAnswers.map(f => {
                n.push(f);
                return f;
            });
            if (n && n.length) {
                n.map((v, k) => {
                    v.code = k;
                    return v;
                });

                this.setState({
                    listAnswers: n
                });
            }
        }
    };

    // handle image upload
    onFileChange = (e, prop) =>{
        // setErrText('');

        let temp = _.get(e,'target.files[0]',false);

        if(temp){
            if(e.target.files[0].size > 999999){
                this.showMessage(false,'This file is too big. Maximum size is 1MB.',true);
            }else if(
                e.target.files[0].name.toLowerCase().search('.jpeg') === -1 &&
                e.target.files[0].name.toLowerCase().search('.jpg') === -1  &&
                e.target.files[0].name.toLowerCase().search('.png') === -1){

                this.showMessage(false,'This file is invalid. Only accept .jpg, .jpeg and .png',true);

            }else{
                let __image_file = this.state.imageFile ?? {};
                if(__image_file[prop] && __image_file[prop].length){
                    __image_file[prop].push(e.target.files[0]);
                }
                else{
                    __image_file[prop] = [e.target.files[0]];
                }

                let upload_image = [e.target.files[0]];

                this.setState({
                    imageFile: __image_file
                },()=>{
                    this.handleUpload(upload_image,prop);
                });

            }
        }
    };

    handleUpload = (e,prop) => {
        var formData = new FormData();
        for (const key of Object.keys(e)) {
            formData.append('imgCollection', e[key])
        }

        Api.putFile('/uploads',{
            method: 'POST',
            body: formData
        }).then(resp => {
            if(resp.images && resp.images.length){
                let nData = this.state?.imagePut ?? {};

                resp.images.map(v=>{
                    if(nData[prop] && nData[prop].length) {
                        nData[prop].push(v);
                    }
                    else{
                        nData[prop] = [v];
                    }
                    return v;
                });

                this.setState({
                    imagePut: nData
                })
                this.showMessage(true,resp?.message ?? 'Connection failed, please try again to upload.')
            }
            else {
                this.showMessage(false,resp?.message ?? 'Connection failed, please try again to upload.')
            }

        }).catch(err => {
            this.showMessage(false,err?.message ?? 'Connection failed, please try again to upload.')

        });
    }

    handleDeleteUpload = (e,prop) =>{
        let sData = this.state.imageFile;
        let aData = sData[prop];
        aData.splice(e,1);
        sData[prop] = aData;

        let pData = this.state.imagePut;
        let bData = pData[prop];
        bData.splice(e,1);
        pData[prop] = bData;

        let dataAns = this.state.listAnswers;
        if(dataAns.length){// === '_ansImg'){
            dataAns.map((v,k)=>{
                if(prop === '_ansImg'+k){
                    // dataAns[k][]
                    v.images.splice(e,1);
                }
                return v;
            })
        }

        this.setState({
            imageFile: sData,
            imagePut: pData,
            // listAnswers: dataAns
        },()=>{});
    }

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_theory' >{text.label_master_theory}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_add} {text.label_questions}</li>
                    </ol>
                </nav>

                <div className='row'>
                    {this.state.disableUpdate && <div className='col-lg-12 col-xl-12 mb-2'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{text.label_detail +' '+ text.label_theory}
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className='float-right mb-3'
                                    onClick={() => this.handleGo('/master_theory')}
                                    disabled={this.state.loadingButton}
                                >
                                    {text.button_back} {this.state.loadingButton &&
                                <i className='fa fa-spinner fa-spin'> </i>}
                                </Button>
                            </h2>
                            <div className='col-12 mb-2'>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-6 col-sm-12'>
                                        <div className='row m-0'>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_theory_name}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state.name}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_category}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.category?.name ?? '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_subcategory}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.subcategory?.name ?? '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_status}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.active ?
                                                <label className='w-75 badge badge-success' >Aktif</label> :
                                                <label className='w-75 badge badge-dark' >Not Aktif</label>
                                            }
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_duration}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.duration ? this.state?.duration +' '+text.label_minute : '-'}
                                            </div>
                                            <div className='col-4 p-0 mb-2'>
                                                {text.label_view}
                                            </div>
                                            <div className='col-8 p-0 mb-2'>
                                                : {this.state?.view ?? '-'}
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                    {/*<div className='col-md-6 col-lg-6 col-sm-12'>*/}
                                    {/*    <div className='row m-0'>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            {text.label_testing_all}*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            : 0*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            {text.label_testing_before}*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            : 0*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            {text.label_testing_after}*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            : 0*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            {text.label_testing_sum_question}*/}
                                    {/*        </div>*/}
                                    {/*        <div className='col-6 p-0 mb-2'>*/}
                                    {/*            : {this.state.sumQuestion}*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <hr/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <h6 className='col-4 mb-2'>
                                {text.label_theory_descriptions} :
                            </h6>
                            <div className='col-8 mb-2'></div>
                            {this.state?.descriptions && <div
                                className='col-12 mb-2 text-justify'
                                dangerouslySetInnerHTML={{__html: this.state.descriptions.split('\n').join('<br/>')}}
                            ></div>}
                        </div>
                    </div>}

                    <div className='col-lg-12 col-xl-12 mt-2'>
                        <div className='card-white'>
                            <h4 className='text-uppercase'>
                                {(this.state.disableUpdate ? text.label_detail : text.button_add+'/'+text.button_edit )+' '+ text.label_questions}
                                {this.state.disableUpdate &&
                                <Button
                                    variant='contained'
                                    color={this.state.disableUpdate ? 'primary' : 'secondary'}
                                    className='float-right mb-3'
                                    onClick={() => this.enableUpdate(this.state.disableUpdate)}
                                    disabled={this.state.loadingButton}
                                >
                                    {this.state.disableUpdate ? text.button_add : text.button_back} {text.label_questions}
                                    {this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                                </Button>}
                            </h4>

                            {/* list table */}
                            {this.state.disableUpdate &&
                            <div className='col'>
                                <div className='d-inline-flex align-items-center w-100 justify-content-between my-2'>
                                    <SearchInput
                                        placeholder={text.label_browse + ' ' + text.label_theory}
                                        value={this.state.keyword}
                                        onSubmit={(x) => this.__handleSearchEvent(x, 'keyword')}
                                        onKeyPress={(x) => this.__handleSearchEvent(x, 'keyword')}
                                        onKeyUp={(x) => this.__handleSearchEvent(x, 'keyword')}
                                    />
                                </div>

                                <div className='clearfix'> </div>
                                <div className='table-wrapper'>
                                <Table className='table-list' size='small' >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='clickable' onClick={() => this.handleSort('code')} >{text.label_questions_code}
                                            {this.state.orderBy === 'code' && (
                                                <span className='icon-sort'>{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className='fas fa-sort-up'> </i>
                                                    ) : (
                                                        <i className='fas fa-sort-down'> </i>
                                                    )
                                                }</span>
                                            )}
                                            </TableCell>
                                            <TableCell className='clickable' onClick={() => this.handleSort('question')} >{text.label_questions}
                                            {this.state.orderBy === 'question' && (
                                                <span className='icon-sort'>{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className='fas fa-sort-up'> </i>
                                                    ) : (
                                                        <i className='fas fa-sort-down'> </i>
                                                    )
                                                }</span>
                                            )}
                                            </TableCell>
                                            <TableCell className='clickable' onClick={() => this.handleSort('type')} >{text.label_questions_type} {text.label_questions}
                                            {this.state.orderBy === 'type' && (
                                                <span className='icon-sort'>{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className='fas fa-sort-up'> </i>
                                                    ) : (
                                                        <i className='fas fa-sort-down'> </i>
                                                    )
                                                }</span>
                                            )}
                                            </TableCell>
                                            <TableCell className='clickable' onClick={() => this.handleSort('example')} >{text.label_questions_example} {text.label_questions}
                                            {this.state.orderBy === 'example' && (
                                                <span className='icon-sort'>{
                                                    this.state.sortedBy === 'asc' ? (
                                                        <i className='fas fa-sort-up'> </i>
                                                    ) : (
                                                        <i className='fas fa-sort-down'> </i>
                                                    )
                                                }</span>
                                            )}
                                            </TableCell>
                                            <TableCell align='center'>{text.label_action}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.loading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align='center' className='py-5'>
                                                <CircularProgress color='primary'/>
                                            </TableCell>
                                        </TableRow>
                                        ) : (
                                        this.state.rows && this.state.rows.length === 0 ? (
                                        <TableRow style={{height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={5}
                                                       style={{textAlign: 'center'}}>{text.label_no}</TableCell>
                                            </TableRow>
                                        ) : (
                                        this.state.rows.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            style={{cursor: 'pointer'}}
                                            onDoubleClick={_ => {
                                                // onClick={_ => {
                                                this.handleOpen(row)
                                            }}
                                            // key={row.id}
                                            selected={row.selected}>
                                            <TableCell>{row.code >= 0 ? row.id+'/'+(row.code+1) : '-'}</TableCell>
                                            <TableCell>{row.question && row.question.length ?
                                                    <div className="row">
                                                    {row.question.map((v,k)=> {
                                                        return <label key={k} className='text-muted col-12'>
                                                            {v.question.length >= 10 ? v.question.substr(0, 10)+'...':v.question}
                                                        </label>;
                                                    })}</div>
                                                : '-'}
                                            </TableCell>
                                            <TableCell>{row.type ?
                                                this.state.listType.map(n=> {
                                                    if(n.id === row.type) {
                                                        return n.name;
                                                    }
                                                    // return n;
                                                })
                                                : '-'}</TableCell>
                                            <TableCell>{row.example ?
                                                <label className='w-75 badge badge-success'>Yes</label> :
                                                <label className='w-75 badge badge-dark'>No</label>
                                            }</TableCell>
                                            <TableCell>
                                                <div className='text-center'>
                                                    {/*<button className='btn-icon' onClick={() =>this.handleOpen(row)}>*/}
                                                    {/*    <i className='fas fa-tasks'> </i>*/}
                                                    {/*</button>*/}
                                                    <button className='btn-icon'
                                                            onClick={() => this.handleOpenDelete(row)}>
                                                        <i className='fas fa-trash-alt'> </i>
                                                    </button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        )))
                                        )}
                                    </TableBody>
                                </Table>
                                </div>

                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component='div'
                                    className='responsive'
                                    count={this.state.total}
                                    rowsPerPage={this.state.perPage}
                                    page={this.state.currentPageTable}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </div>}

                            {/* add data questions */}
                            {this.state.disableUpdate === false &&<div className='col'>
                            {/*<form name='add' id='addProduct' className='row' noValidate>*/}
                                <div className='col-12 mb-2'>
                                    <div className='row'>
                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                            <div className='row m-0 border-bottom'>
                                                <div className='form-group col-6 p-0 mb-2'>
                                                    {text.label_questions_code} : {'@ '+(Number(this.state.code) + 1)}
                                                </div>
                                                <div className='form-group col-6 p-0 mb-2'>
                                                    <TextField
                                                        type='text'
                                                        id='code'
                                                        name='code'
                                                        onChange={(e) => this.handleChange(e, 'code')}
                                                        value={this.state.code}
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>
                                            {/*<div className='form-group'>*/}
                                            {/*    <TextField*/}
                                            {/*        type='text'*/}
                                            {/*        id='questions'*/}
                                            {/*        name='questions'*/}
                                            {/*        label={this.state.view=== 'Table' ? text.label_questions1 : text.label_questions}*/}
                                            {/*        // placeholder={text.label_theory_descriptions}*/}
                                            {/*        onChange={(e) => this.handleChange(e, 'questions')}*/}
                                            {/*        value={this.state.questions}*/}
                                            {/*        fullWidth*/}
                                            {/*        multiline*/}
                                            {/*        rows={5}*/}
                                            {/*    />*/}
                                            {/*    /!*{this.validator.message('questions', this.state.questions, 'required')}*!/*/}
                                            {/*    <div className='text-danger'>{this.state.errors.questions}</div>*/}
                                            {/*</div>*/}

                                            <MultipleQuestions
                                                text={text}
                                                view={this.state.view}
                                                typeOptions={true}
                                                listOptions={ this.state.listQuestions }// listArray
                                                handleChangeAnswer={this.handleChangeAnswer}
                                                handlePlus={this.handlePlus}
                                                handleMinus={this.handleMinus}
                                            />

                                            {/* uploaad image soal */}
                                            <MultipleUpload
                                                handleUpload={this.handleUpload}
                                                onFileChange={this.onFileChange}
                                                keys={'__imgQuest'+this.state.code + 1}
                                                id={'__imgQuest'+this.state.code + 1}
                                                text={text}
                                                handleDeleteUpload={this.handleDeleteUpload}
                                                imagePut={this.state.imageFile}
                                                disableBtn={false}
                                            />
                                        </div>

                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                            <div className='row m-0 border-bottom'>
                                                <div className='form-group col-7 p-0'>
                                                    <TextField
                                                        select
                                                        id='type'
                                                        name='type'
                                                        label={text.label_questions_type}
                                                        onChange={(e) => this.handleChange(e, 'type')}
                                                        value={this.state.type}
                                                        fullWidth
                                                    >
                                                        {this.state.listType.map((data, idl) => (
                                                            <MenuItem key={idl} value={data.id}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    {this.validator.message('type', this.state.type, 'required')}
                                                    <div className='text-danger'>{this.state.errors.type}</div>
                                                </div>
                                                <div className='form-group col-5 p-0'>
                                                    <FormControl component='fieldset' className='col'>
                                                        <FormGroup className='row'>
                                                            <FormControlLabel
                                                                control={
                                                                    <AntSwitch
                                                                        checked={this.state.examples}
                                                                        onChange={this.handleChecked('examples')}
                                                                        name="examples"
                                                                    />
                                                                }
                                                                className='col-12'
                                                                label={text.label_questions_example+' '+ text.label_questions+' : '}
                                                                labelPlacement="top"
                                                            />
                                                            {this.validator.message('examples', this.state.examples, 'required')}
                                                            <div className='text-left text-alert'>{this.state.errors.examples}</div>
                                                        </FormGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <MultipleAnswers
                                                keysImg='_ansImg'
                                                id='_ansImg'
                                                text={text}
                                                imageFile={this.state.imageFile}
                                                onFileChange={this.onFileChange}
                                                handleUpload={this.handleUpload}
                                                handleDeleteUpload={this.handleDeleteUpload}
                                                // disableBtn={}

                                                // data options
                                                typeOptions={(this.state.type === 'radio' || this.state.type === 'checkbox') ? true: false}
                                                listOptions={ this.state.listAnswers }// listArray
                                                handleChangeAnswer={this.handleChangeAnswer}
                                                handlePlus={this.handlePlus}
                                                handleMinus={this.handleMinus}
                                            />
                                            {/*{(this.state.type === 'radio' || this.state.type === 'checkbox') &&*/}
                                            {/*<div className='row m-0 py-2'>*/}
                                            {/*        { this.state.listAnswers.map((item,idx) => (*/}
                                            {/*            <div className='row border-bottom m-0' key={idx}>*/}
                                            {/*                {(this.state.listAnswers.length -1 === idx) ?*/}
                                            {/*                <div className='form-group col-md-9 m-0 py-2 text-center'>*/}
                                            {/*                    <TextField*/}
                                            {/*                        type='text'*/}
                                            {/*                        id={'label_' + idx}*/}
                                            {/*                        name={'label_' + idx}*/}
                                            {/*                        label={text.label_answer_title}*/}
                                            {/*                        placeholder={text.label_answer_title}*/}
                                            {/*                        onChange={(e) => this.handleChangeAnswer(e, 'label', idx)}*/}
                                            {/*                        value={item.label}*/}
                                            {/*                        fullWidth*/}
                                            {/*                    />*/}
                                            {/*                </div> :<>*/}
                                            {/*                    <div className='form-group col m-0 py-2'>*/}
                                            {/*                        {text.label_answer_title}:*/}
                                            {/*                    </div>*/}
                                            {/*                    <div className='form-group col m-0 py-2'>*/}
                                            {/*                        { item.label}*/}
                                            {/*                    </div></>*/}
                                            {/*                }*/}
                                            {/*                <div className='form-group col-md-3 m-0 py-2 text-center'>*/}
                                            {/*                    {(this.state.listAnswers.length -1 === idx) &&*/}
                                            {/*                        <button className='btn btn-info'*/}
                                            {/*                             onClick={() => this.handlePlus(idx)}>+</button>*/}
                                            {/*                    }*/}
                                            {/*                    <button className='btn btn-secondary'*/}
                                            {/*                            onClick={() => this.handleMinus(idx)}>-</button>*/}
                                            {/*                </div>*/}
                                            {/*                {(this.state.listAnswers.length -1 === idx) ?*/}
                                            {/*                    <div className='form-group col-12 m-0 py-2 mb-2'>*/}
                                            {/*                        <TextField*/}
                                            {/*                            type='text'*/}
                                            {/*                            id={'answer_' + idx}*/}
                                            {/*                            name={'answer_' + idx}*/}
                                            {/*                            label={text.label_answer_subject}*/}
                                            {/*                            placeholder={text.label_answer_subject}*/}
                                            {/*                            onChange={(e) => this.handleChangeAnswer(e, 'answer', idx)}*/}
                                            {/*                            value={item.answer}*/}
                                            {/*                            fullWidth*/}
                                            {/*                            multiline*/}
                                            {/*                            rows={5}*/}
                                            {/*                        />*/}
                                            {/*                    </div> :<>*/}
                                            {/*                    <div className='form-group col-md-12 m-0 py-2'>*/}
                                            {/*                        {text.label_answer_subject}:*/}
                                            {/*                    </div>*/}
                                            {/*                    <div className='form-group col-md-12 m-0 py-2'>*/}
                                            {/*                        { item.answer}*/}
                                            {/*                    </div></>*/}
                                            {/*                }*/}
                                            {/*                <MultipleUpload*/}
                                            {/*                    onFileChange={this.onFileChange}*/}
                                            {/*                    handleUpload={this.handleUpload}*/}
                                            {/*                    keys={'_ansImg'+idx}*/}
                                            {/*                    id={'_ansImg'+idx}*/}
                                            {/*                    text={text}*/}
                                            {/*                    imagePut={this.state.imageFile}*/}
                                            {/*                    handleDeleteUpload={this.handleDeleteUpload}*/}
                                            {/*                    disableBtn={this.state.listAnswers.length -1 === idx ? false : true}*/}
                                            {/*                />*/}
                                            {/*            </div>*/}
                                            {/*        ))}*/}
                                            {/*</div>*/}
                                            {/*}*/}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.enableUpdate(this.state.disableUpdate,true)}
                                    >
                                        {text.button_back}
                                    </Button>

                                    {this.state.disableUpdate ?
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.enableUpdate}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.button_edit} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                        :
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='mr-3'
                                            onClick={this.handleSubmit}
                                            disabled={this.state.loadingButton}
                                        >
                                            {text.label_save} {this.state.loadingButton &&
                                        <i className='fa fa-spinner fa-spin'> </i>}
                                        </Button>
                                    }
                                </div>
                            {/*</form>*/}
                            </div>}

                        </div>
                    </div>
                </div>
                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showDialog}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{text.label_delete} {text.label_questions} "{this.state.currDelete}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleDelete}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth='sm'
                    fullWidth={true}
                    open={this.state.showActivate}
                    onClose={this.handleClose}
                    aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>{this.state.accessChange ? text.label_deactivation : text.label_activation} {text.label_theory} "{this.state.currChange}" {text.label_now}?</DialogTitle>
                    <DialogContent>
                        {/*<p>{text.text_delete}</p>*/}
                    </DialogContent>
                    <DialogActions className='text-center'>
                        <Button
                            variant='contained'
                            className='mr-3'
                            onClick={this.handleClose}
                        >
                            {text.button_no}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            className=''
                            onClick={this.handleChange}
                            disabled={this.state.loadingButton && 'disabled'}
                        >
                            {text.button_yes}{this.state.loadingButton && <i className='fa fa-spinner fa-spin'> </i>}
                        </Button>
                    </DialogActions>
                </Dialog>

                <ToastContainer />
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);