import Moment from "moment";
import AuthHelper from "./auth-helper";
import languange from "./label-lang";

require('dotenv').config();

class Ages {

    static calculateOnly(birthday) {
        if(birthday){
            var bod = Moment(birthday, "YYYY-MM-DD");
            var yom = Moment().diff(bod, 'months',true);
            /* only years !^ */
            var years = Math.floor(yom/12);
            /* only years ^ */
            /* Math.round(years/12), */
            /* Math.ceil(years/12), */
            /* (yom - (Math.floor(yom/12)* 12)).toFixed(2); */
            var mod = (yom - (years* 12)).toFixed(2);
            /* only month ^ */
            /* Math.ceil(yom - (Math.floor(yom/12)* 12)), */
            /* only years !^ */
            /* Math.round(yom - (Math.floor(yom/12)* 12)), */
            /* Math.floor(yom - (Math.floor(yom/12)* 12)) */

            // var years = Moment().diff(m, 'years', false);
            // var days = Moment().diff(m.add(years, 'years'), 'days', false);
            // if(years !== 0 && mod === 0){
            //     return (years + ' '+yearsTxt);
            // }
            // if(years === 0 && mod !== 0){
            //     return (mod + ' '+monthTxt);
            // }
            // else {
            //     return (years + ' '+yearsTxt+', ' + mod + ' '+monthTxt);
            // }
            return {
                months: mod,
                years: years
            };
        }
        return {
            months: 0,
            years: 0
        };
    }
    static calculate(birthday, yearsTxt,monthTxt) {
        if(!monthTxt && !yearsTxt){
            let lang = AuthHelper.getLang();
            let dataLang = {};
            if(lang === 'indo') {
                dataLang = languange.id;
            }else {
                dataLang = languange.en;
            }
            const text = dataLang;
            yearsTxt = text.label_years;
            monthTxt = text.label_month;

        }
        const {years,months} = this.calculateOnly(birthday);

        if(years !== 0 && months === 0){
            return (years + ' '+yearsTxt);
        }
        else if(years === 0 && months !== 0){
            return (months + ' '+monthTxt);
        }
        else if(years !== 0 && months !== 0){
            return (years + ' '+yearsTxt+', ' + months + ' '+monthTxt);
        }
        else
        {
            return null;
        }
    }

    static age(birthday,condition=false){
        if(condition){
            return this.calculateOnly(birthday);
        }
        else {
            return this.calculate(birthday);
        }
    };

}

export default Ages;