import React, {Component} from 'react';
import ContentDashboard from "../../content-dashboard";
import NotFound from "../not-found";
import {Switch} from 'react-router-dom';
import AuthHelper from "../../../../libraries/auth-helper";
import {PrivateRoute} from '../../../../libraries/route-handling'
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import {history} from "../../../../shared/configure-store";
// import Print from '../reservation/print';
import Sidebar from '../../sidebar';
import Navbar from '../../navbar';
import Copyright from '../../../presentational/copyright';

// ------ FIX Route

import ManageUsers from "../users";
import UsersAdd from "../users/add";
import UsersDetail from "../users/detail";

import MasterCategory from "../categories";
import CategoryAdd from "../categories/add";
import CategoryDetail from "../categories/detail";

import MasterSubcategory from "../subkategories";
import subcategoryAdd from "../subkategories/add";
import subcategoryDetail from "../subkategories/detail";

import MasterTheory from "../theories";
import theoryAdd from "../theories/add";
import theoryDetail from "../theories/detail";
import questionsDetailAdd from "../theories/addQuestions";

import MasterQuestions from "../questions";
import QuestionsAdd from "../questions/add";
import QuestionsDetail from "../questions/detail";

import Schedules from "../schedules";
import SchedulesAdd from "../schedules/add";
import SchedulesDetail from "../schedules/detail";

import QuestionsTest from "../testing";

import Profile from "../me";

import Company from "../company";

// ------

class Dashboard extends Component {


    componentDidMount() {
        if(AuthHelper.isLoggedIn()){
            let profile = AuthHelper.getProfile();

            let user = JSON.parse(JSON.stringify(profile));

            this.props.setProfile(user);
        }else{
            history.push('/login');
        }
    }

    render() {

        return (
            <div>
                <main className="dashboard container-fluid">
                    <div className='row'>
                        <div className='col-md pr-md-0' style={{maxWidth:'270px'}}>
                            <Sidebar />
                        </div>
                        <div className='col-md pl-md-0'>
                            <Navbar />
                            <section className='section-dashboard'>
                                <div className='main-container'>
                                    <Switch>
                                        <PrivateRoute exact path='/' component={ContentDashboard}/>
                                        <PrivateRoute exact path='/manage_users' component={ManageUsers} />
                                        <PrivateRoute exact path='/manage_users/add' component={UsersAdd} />
                                        <PrivateRoute exact path="/manage_users/:id" component={UsersDetail}/>

                                        <PrivateRoute exact path='/master_category' component={MasterCategory} />
                                        <PrivateRoute exact path='/master_category/add' component={CategoryAdd} />
                                        <PrivateRoute exact path="/master_category/:id" component={CategoryDetail} />

                                        <PrivateRoute exact path='/master_subcategory' component={MasterSubcategory} />
                                        <PrivateRoute exact path='/master_subcategory/add' component={subcategoryAdd} />
                                        <PrivateRoute exact path="/master_subcategory/:id" component={subcategoryDetail}/>

                                        <PrivateRoute exact path='/master_theory' component={MasterTheory} />
                                        <PrivateRoute exact path='/master_theory/add' component={theoryAdd} />
                                        <PrivateRoute exact path="/master_theory/:id" component={theoryDetail}/>
                                        <PrivateRoute exact path="/master_theory/questions/:id" component={questionsDetailAdd}/>

                                        <PrivateRoute exact path='/master_questions' component={MasterQuestions} />
                                        <PrivateRoute exact path='/master_questions/add' component={QuestionsAdd} />
                                        <PrivateRoute exact path="/master_questions/:id" component={QuestionsDetail}/>

                                        <PrivateRoute exact path='/schedules' component={Schedules} />
                                        <PrivateRoute exact path='/schedules/add' component={SchedulesAdd} />
                                        <PrivateRoute exact path="/schedules/:id" component={SchedulesDetail}/>

                                        <PrivateRoute exact path="/questions/:id" component={QuestionsTest}/>

                                        <PrivateRoute exact path="/me" component={Profile}/>

                                        <PrivateRoute exact path="/company" component={Company}/>

                                        <PrivateRoute component={NotFound}/>
                                    </Switch>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>

                <footer className='dashboard-footer'>
                    <p className='mb-0'><Copyright /></p>
                </footer>


            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

