
const text = {
    id: {
        label_years: 'Tahun',
        label_month: 'Bulan',

        login_heading: 'MASUK',
        login_welcome: 'Selamat Datang',
        login_paragraph: 'Silahkan masuk dengan akun Anda',
        login_register: 'Buat Akun',
        login_forgot: 'Lupa Kata Sandi',

        forgot_heading: 'LUPA KATA SANDI ANDA',
        forgot_paragraph: 'Silakan masukkan alamat email Anda dan kami akan mengirimkan tautan untuk mengganti kata sandi Anda.',

        forgot_success_heading: 'Periksa Email Anda!',
        forgot_success_paragraph: 'Kami baru saja mengirimi Anda email petunjuk untuk mereset kata sandi Anda',

        register_heading: 'BUAT AKUN',
        register_step: 'LANGKAH 1 DARI 2',
        register_have_account: 'Sudah mempunyai akun',
        register_login: 'Masuk disini',
        register2_heading: 'MEMBUAT INFORMASI PERUSAHAAN',
        register2_step: 'LANGKAH 2 DARI 2',
        register2_tnc: 'Saya menerima syarat dan ketentuan & kebijakan privasi',

        success_heading: 'Terima Kasih',
        success_company_paragraph: 'Silakan periksa email Anda untuk aktivasi akun.',
        success_company_detail: 'Kami telah mengirimi Anda email dengan tautan untuk aktivasi akun',

        landing_paragraph: 'Terima kasih telah bergabung bersama kami!',
        reservation_welcome: 'Selamat Datang',
        reservation_timer: 'Update Waktu Tersisa Reservasi',
        reservation_time_up: 'Waktu sudah habis. Jawaban anda akan disimpan otomatis',
        reservation_paragraph1: 'Siap menambah reservasi pertama Anda?',
        reservation_paragraph2: 'Silakan masukkan di bawah ini, atau Anda dapat melewati sekarang.',
        reservation_heading: 'Buat Reservasi Baru',
        reservation_step: 'Langkah',
        reservation_step1_text: 'Pilih Gudang Penyimpanan & Waktu Pengiriman',
        reservation_step1_alert: 'Slot Waktu Tersedia! Mohon untuk tepat waktu saat pengiriman barang.',
        reservation_step2_text: 'Pilih Produk Yang Akan Dikirim',
        reservation_step3_text: 'Cetak Label',
        reservation_product: 'Daftar Produk Anda',

        product_alert: 'Masukkan ukuran dengan tepat',

        note_heading: 'Catatan',
        body_for: 'untuk',
        note_text: 'cetak nota ini untuk bukti penyerahan produk saat pengiriman. Tempelkan Kode Produk (IKU) ke setiap produk yang terdaftarkan dengan tepat.',

        label_nama: 'Nama Lengkap',
        label_alamat: 'Alamat',
        label_user: 'Nama User',
        

        label_password: 'Kata Sandi',
        label_password_confirm: 'Konfirmasi Kata Sandi',
        label_first_name: 'Nama Depan',
        label_last_name: 'Nama Belakang',
        label_company: 'Nama Perusahaan',

        label_state: 'Provinsi',
        label_zipcode: 'Kode Pos',

        label_name_contact: 'Nama Kontak',
        label_handphone: 'Nomor Handphone',
        label_dashboard: 'Beranda',
        label_reservation: 'Kategori',

        label_activation: 'Aktifkan',
        label_deactivation: 'Bekukan',

        label_reservation_code: 'Kode Reservasi',
        label_reservation_date: 'Tgl Reservasi',
        label_birth_place: 'Tempat lahir',
        label_age: 'Usia',
        label_birth_date: 'Tgl lahir',
        label_admins: 'Admin',
        label_users: 'Pengguna',
        label_orders: 'Fulfillment',
        label_tenants: 'Penyewa',
        label_assets: 'Inventaris',
        label_roles: 'Peran',
        label_logout: 'Keluar',
        label_action: 'Aksi',
        label_status: 'Status',
        label_history: 'Riwayat',

        label_hour: 'Jam',
        label_minute: 'Menit',
        label_second: 'Detik',
        label_warehouse_place: 'Alamat Tempat Penyimpanan',
        label_warehouse_address: 'Alamat Tempat Penyimpanan',
        label_warehouse: 'Pilih Gudang Penyimpanan',
        label_time: 'Waktu Pengiriman',

        label_sku: 'SKU Produk',
        label_quantity: 'Jumlah',
        label_slot: 'Slot',
        label_unavailable: 'Penyimpanan tidak tersedia',

        label_add_product: 'Tambah Produk',
        label_storage: 'Kategori Penyimpanan',
        label_weight: 'Berat',
        label_width: 'Lebar',
        label_height: 'Tinggi',
        label_length: 'Panjang',
        label_locker: 'Ukuran Loker',
        label_no_locker: 'tidak ditemukan',
        label_packaging: 'Kemasan',
        label_other: 'Lainnya',
        label_shelf: 'Umur Simpan',
        label_days: 'Hari',

        label_order_code: 'Kode Pesanan',
        label_order_date: 'Tgl Pesanan',
        label_receiver: 'Penerima',
        label_receiver_contact: 'Kontak Penerima',
        label_receiver_phone: 'Telepon Penerima',
        label_platform: 'Platform',
        label_logistic: 'Logistik',

        label_no: 'Data kosong',
        label_not_found: 'Data tidak ditemukan',

        button_login: 'MASUK',
        button_submit: 'Kirim',
        button_next: 'LANJUTKAN',
        button_back: 'Kembali',
        button_register: 'DAFTAR',
        button_add_list: 'Tambah Daftar',
        button_add: 'Tambah',
        button_done: 'SELESAI',
        button_skip: 'Lewati',
        button_edit: 'Ubah',
        button_print: 'Unduh Jawaban',
        button_yes: 'Ya',
        button_no: 'Tidak',

        //start labeling reservations
        label_contact:'Kontak',
        label_sortBy:'Urutkan Berdasarkan',
        label_stock_unavailable:'Stok produk tidak tersedia',

        // new label ind
        label_schedules: 'Jadwal Test',
        label_schedules_done: 'Jadwal Test Tidak Berlaku',
        label_schedules_name: 'Materi Test',
        label_schedules_answer: 'Jawaban',

        label_master_subcategory: 'Subkategori',
        label_master_questions: 'Peserta Test',
        label_questions_expire: 'Tgl berakhir',
        label_master_theory: 'Bank soal',
        label_manage_users: 'Akun pengguna',
        label_master_category: 'Kategori',
        label_template_product: 'Master template produk',
        label_transactions: 'Transaksi',
        label_koperasi: 'Koperasi',
        label_history_transactions: 'Riwayat Transaksi',

        label_province: 'Provinsi',
        label_city: 'Kota/Kabupaten',
        label_district: 'Kecamatan',
        label_subdistrict: 'Kelurahan',

        label_profile: 'Biodata peserta',
        label_my_profile: 'Biodata saya',

        label_category: 'Kategori',
        label_view: 'Bentuk Tampilan',
        label_subcategory: 'Subkategori',
        label_questions: 'Pertanyaan',
        label_questions1: 'Penyataan',
        label_questions_code: 'Kode/Nomor',
        label_questions_type: 'Jenis Jawaban',
        label_answer_title: 'Judul Jawaban',
        label_answer_subject: 'Isi Jawaban',
        label_questions_example: 'Contoh',
        label_theory: 'Materi',
        label_category_name: 'Nama Kategori',
        label_subcategory_name: 'Nama Subkategori',
        label_questions_name: 'Nama Pertanyaan',
        label_theory_name: 'Nama Materi',

        label_testing_all: 'Mengikuti Test',
        label_testing_before: 'Belum Test',
        label_testing_after: 'Selesai Test',
        label_testing_sum_question: 'Jumlah Pertanyaan Test',

        label_duration: 'Waktu Pengerjaan',
        label_category_descriptions: 'Deskripsi Kategori',
        label_subcategory_descriptions: 'Deskripsi Subkategori',
        label_questions_descriptions: 'Deskripsi Pertanyaan',
        label_theory_descriptions: 'Deskripsi Materi',
        label_save: 'Simpan',
        label_detail: 'Rincian',

        label_products: 'Produk',
        label_product: 'Nama Produk',
        label_product_price: 'Harga Pokok',
        label_selling_price: 'Harga Jual',
        label_descriptions: 'Deskripsi',
        label_upload: 'Unggah gambar',
        label_tooltip: 'Maksimum ukuran gambar : 1 MB',
        label_stock:'Stok produk',
        label_sold:'Terjual',
        label_photo: 'Photo Produk',

        label_templates: 'Template',
        label_template: 'Nama Template',
        label_template_price: 'Harga Template',
        label_template_desc: 'Deskripsi Template',

        label_schools: 'Sekolah',
        label_school: 'Nama Sekolah',
        label_school_price: 'Harga Sekolah',
        label_school_desc: 'Deskripsi Sekolah',

        label_address: 'Alamat',

        label_nip: 'NIP',
        label_name: 'Nama Lengkap',
        label_phone: 'Nomor Telepon',
        label_email: 'Email',

        label_account: 'Akun',
        label_rules: 'Akses',

        label_browse: 'Cari',
        label_delete: 'Hapus',
        label_username: 'Nama Akun',
        label_available: 'Tersedia',
        label_cart: 'Masukkan ke keranjang',
        label_checkout: 'Pesan sekarang',

        label_religion: 'Agama',
        label_now: 'Sekarang',

        label_data_success: 'Data berhasil di ',
        label_gender: 'Jenis kelamin',
        label_citizenship: 'Kewarganegaraan',
        label_tribes: 'Suku/asal bangsa',

    },
    en: {
        label_years: 'Years',
        label_month: 'Month',

        login_heading: 'LOGIN',
        login_welcome: 'Welcome',
        login_paragraph: 'Please login your account',
        login_register: 'Register',
        login_forgot: 'Forgot Password',

        forgot_heading: 'FORGOT YOUR PASSWORD',
        forgot_paragraph: 'Please fill your email and we will send the email to reset your password.',

        forgot_success_heading: 'Please Check Your Email!',
        forgot_success_paragraph: 'We just sent you an email on how to reset your password.',

        register_heading: 'REGISTER',
        register_step: 'STEP 1 OF 2',
        register_have_account: 'Have account?',
        register_login: 'Login here',
        register2_heading: 'CREATE COMPANY INFORMATION',
        register2_step: 'STEP 2 OF 2',
        register2_tnc: 'I accept the terms, condition and privacy policy',

        success_heading: 'Thank You',
        success_company_paragraph: 'Please check your email to activate yout account',
        success_company_detail: 'We already send you email to activate your account',

        landing_paragraph: 'Thank you for joining us!',
        reservation_welcome: 'Welcome',
        reservation_timer: 'Time left remaining for Reservation',
        reservation_time_up: 'Time Up. You`r answers has been saved automatically',
        reservation_paragraph1: 'Ready to add your first reservation?',
        reservation_paragraph2: 'Please continue or you might to skip.',
        reservation_heading: 'Create New Reservation',
        reservation_step: 'Step',
        reservation_step1_text: 'Choose Warehouse and Shipping Time',
        reservation_step1_alert: 'Slot Time Available! Please be on time for the shipping time.',
        reservation_step2_text: 'Choose your products to send',
        reservation_step3_text: 'Print Label',
        reservation_product: 'Your Product List',

        product_alert: 'Input the correct size',

        note_heading: 'Notes',
        body_for: 'for',
        note_text: 'print this as a proof when shipping. Paste the Product Code (IKU) into each product that is registered correctly.',

        label_password: 'Password',
        label_password_confirm: 'Password Confirmation',
        label_first_name: 'First Name',
        label_last_name: 'Last Name',
        label_company: 'Company Name',
        label_state: 'State',
        label_zipcode: 'Zip Code',
        label_name_contact: 'Contact Name',
        label_handphone: 'Contact Phone Cell',
        label_dashboard: 'Home',
        label_reservation: 'Reservations',

        label_activation: 'Activate',
        label_deactivation: 'Deactivate',

        label_reservation_code: 'Reservation Code',
        label_reservation_date: 'Reservation Date',
        label_birth_place: 'Birth of place',
        label_age: 'Age',
        label_birth_date: 'Birth of date',
        label_admins: 'Admins',
        label_users: 'Users',
        label_orders: 'Fulfillment',
        label_tenants: 'Tenants',
        label_assets: 'Assets',
        label_roles: 'Roles',
        label_logout: 'Sign out',
        label_action: 'Action',
        label_status: 'Status',
        label_history: 'Riwayat',

        label_hour: 'Hours',
        label_minute: 'Minutes',
        label_second: 'Seconds',
        label_warehouse_place: 'Warehouse',
        label_warehouse_address: 'Warehouse Addresses',
        label_warehouse: 'Choose Warehouse',
        label_time: 'Time Shipping',

        label_sku: 'Product SKU',
        label_quantity: 'Quantity',
        label_slot: 'Slot',
        label_unavailable: 'Slot unavailable',

        label_add_product: 'Add Product',
        label_storage: 'Storage',
        label_weight: 'Weight',
        label_width: 'Width',
        label_height: 'Height',
        label_length: 'Length',
        label_locker: 'Locker Size',
        label_no_locker: 'not found',
        label_packaging: 'Packaging',
        label_other: 'Other',
        label_shelf: 'Shelf Life',
        label_days: 'Days',

        label_order_code: 'Order Code',
        label_order_date: 'Order Date',
        label_receiver: 'Receiver',
        label_receiver_contact: 'Receiver Contact',
        label_receiver_phone: 'Receiver Phone',
        label_platform: 'Platform',
        label_logistic: 'Logistic',

        label_no: 'Data empty',
        label_not_found: 'Data not found',

        button_login: 'LOGIN',
        button_submit: 'Submit',
        button_next: 'NEXT',
        button_back: 'Back',
        button_register: 'REGISTER',
        button_add_list: 'Add Row',
        button_add: 'Add',
        button_done: 'DONE',
        button_skip: 'Skip',
        button_edit: 'Edit',
        button_print: 'Download Answer',
        button_yes: 'Yes',
        button_no: 'No',

        //start labeling reservations
        label_contact:'Contact',
        label_sortBy:'Sort by',
        label_stock_unavailable:'Stock product unavailable',

        // new label Eng
        label_schedules: 'Schedule Test',
        label_schedules_done: 'Schedule is expire',
        label_schedules_name: 'Theory Test',
        label_schedules_answer: 'Answer',

        label_master_subcategory: 'Manage subcategory',
        label_master_questions: 'Test taker',
        label_questions_expire: 'Expire date',
        label_master_theory: 'Question bank',
        label_manage_users: 'User account',
        label_master_category: 'Category',
        label_template_product: 'Manage template product',
        label_transactions: 'Transactions',
        label_koperasi: 'Koperasi',
        label_history_transactions: 'Riwayat Transaksi',

        label_province: 'Province',
        label_city: 'City',
        label_district: 'District',
        label_subdistrict: 'Sub District',

        label_profile: 'Participants biodata',
        label_my_profile: 'My profile',

        label_category: 'Category',
        label_view: 'View Types',
        label_subcategory: 'Subcategory',
        label_questions: 'Questions',
        label_questions1: 'Declaration',
        label_questions_code: 'Code/No',
        label_questions_type: 'Type Answer',
        label_answer_title: 'Answer Title',
        label_answer_subject: 'Answer Subject',
        label_questions_example: 'Example',
        label_theory: 'Theory',
        label_category_name: 'Category Name',
        label_subcategory_name: 'Subcategory Name',
        label_questions_name: 'Questions Name',
        label_theory_name: 'Theory Name',

        label_testing_all: 'Taker test',
        label_testing_before: 'Un test',
        label_testing_after: 'Tested',
        label_testing_sum_question: 'Summery Questions',

        label_duration: 'Duration Time Out',
        label_category_descriptions: 'Descriptions Category',
        label_subcategory_descriptions: 'Descriptions Subcategory',
        label_questions_descriptions: 'Descriptions Questions',
        label_theory_descriptions: 'Descriptions Theory',
        label_save: 'Save',
        label_detail: 'Detail',

        label_products: 'Products',
        label_product: 'Product Name',
        label_product_price: 'Product Price',
        label_selling_price: 'Selling Price',
        label_descriptions: 'Description',
        label_upload: 'Upload image',
        label_tooltip: 'Max image size : 1 MB',
        label_stock:'Stock product',
        label_sold:'Sold',
        label_photo: 'Image Product',

        label_templates: 'Template',
        label_template: 'Template Name',
        label_template_price: 'Template Price',
        label_template_desc: 'Template Description',

        label_schools: 'School',
        label_school: 'School Name',
        label_schools_price: 'School Price',
        label_schools_desc: 'School Description',

        label_address: 'Address',

        label_nip: 'NIP',
        label_name: 'Name',
        label_phone: 'Phone',
        label_email: 'Email',

        label_account: 'Account',
        label_rules: 'Access',

        label_browse: 'Browse',
        label_delete: 'Delete',
        label_username: 'Username',
        label_available: 'Available',
        label_cart: 'Add to cart',
        label_checkout: 'Order now',

        label_religion: 'Religion',
        label_now: 'Now',

        label_data_success: 'Data successfully ',
        label_gender: 'Gender',
        label_citizenship: 'Citizenship',
        label_tribes: 'Tribes',


    }
};

export default text;
