import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from '../../../../libraries/auth-helper';

class NavbarGlobal extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);


        this.state = {
            lang: 'indo',
        }
    }

    handleLang  = (lang) => {

        if(this.props.toggle_set_lang) {
            this.props.setToogleLang(lang);
        }
        AuthHelper.setLang(lang);

        this.setState({
            lang: lang,
        })
    };


    render() {

        return(
            <header className="button-language">
              <button
                  className={this.state.lang === 'indo' ? 'active' : ''}
                  onClick={() => this.handleLang('indo')}
              >id</button>
              <button
                  className={this.state.lang === 'en' ? 'active' : ''}
                  onClick={() => this.handleLang('en')}
              >en</button>
            </header>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

        profile_state: state.profile_state

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavbarGlobal));