import Storage from "./storage";
import { history } from "../shared/configure-store"


class AuthHelper {

    static setLogin = (resp) => {

        // Storage.set('access_token', resp.access_token);
        Storage.set('access_token', resp.token);

        // Storage.set('expired_at', resp.expires_in);
        Storage.set('expired_at', resp.expire ? resp.expires : 43200);

    };

    static setLoginReg = (resp) => {

        Storage.set('access_token', resp.token);

    };

    static setProfile = (resp) => {

        Storage.set('profile', resp);

    };

    static setCompany = (resp) => {

        Storage.set('company', resp);

    };

    static getProfile() {

        return Storage.get('profile');

    };

    static getCompany() {

        return Storage.get('company');

    };

    static setLang = (lang) => {

        Storage.set('language',lang);

    };

    static getLang() {

        let lang = Storage.get('language');

        if(lang === null) {
            lang = 'indo';
        }else {
            lang = Storage.get('language');
        }
        return lang;

    };

    static logOut() {

        Storage.clear();

        history.push('/login');

    };

    static isLoggedIn() {

        return !!Storage.get('access_token');

    };

}

export default AuthHelper;
