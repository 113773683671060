import React,{ Component } from 'react';
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";

class Navbar extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {

        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
        this.state = {
            menuOpen: false,
        }

    }

    handleToggleSidebar(){
        if(this.props.toggle_sidebar_state){

            this.props.setHideSidebar();

        }else{

            this.props.setShowSidebar();

        }

    }

    handleLogout = () => {

        AuthHelper.logOut();

    };

    render() {

        return(

            <header className='header-nav row justify-content-end'>
                <nav className='navbar'>
                    <IconButton
                        edge='start'
                        color='default'
                        aria-label='open drawer'
                        className='d-md-none'
                        onClick={this.handleToggleSidebar} >
                        <MenuIcon />
                    </IconButton>
                </nav>
            </header>

        )

    }

}

const mapStateToProps = (state) => {

    return {

        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));