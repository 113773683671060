import React, { useState } from 'react';
import './multiplechoice.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import {FlipUnitContainer} from "./flipclock";
import "./flip.sass";
import dotenv from 'dotenv';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Checkbox} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
dotenv.config();

export const MultipleChoice = ({
        dataQuestions,
        onAnswer,
        loading,
        startTimer,
        labelNext,
        reload,
        handleDone,
        minutes,
        seconds,
        hours,
        countdown,
        typeView,
        nextToSave,
        onChangeInput,
        kraepelin,
        kraepelinSession,
        sessionsTimer,
    })=> {

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [hoursH, setHoursH] = useState(59);
    const [minutesM, setMinutesM] = useState(59);
    const [secondsS, setSecondsS] = useState(59);
    const [hoursShuffle, setHoursShuffle] = useState(true);
    const [minutesShuffle, setMinutesShuffle] = useState(true);
    const [secondsShuffle, setSecondsShuffle] = useState(true);
    // const [findTable, setFindTable] = useState(false);
    // const [questTable, setQuestTable] = useState([]);
    const [questTable] = useState([]);


    const handleAnswerOptionClick = (isCorrect) => {
        const nextQuestion = currentQuestion + 1;

        let checkNextQuest = true;

        if(typeView === 'Table'){
            dataQuestions[currentQuestion].answers.map((v,k)=>{
                if(v.selected === false){
                    checkNextQuest = false;
                }
                return v;
            })
        }
        // else if(typeView === 'Kraepelin' ){
        //     // if()
        // }


        if (nextQuestion < dataQuestions.length && checkNextQuest) {
            setCurrentQuestion(nextQuestion);
        }
    };

    // const onChangeInput = (e,index) => {
    //     let nChange = dataQuestions | [];
    //     if(nChange && nChange[currentQuestion] && nChange?.[currentQuestion]?.answers.length){
    //         dataQuestions[currentQuestion].answers[index].answer = e.target.value;
    //         dataQuestions[currentQuestion].answers[index].label = e.target.value;
    //     }
    // }

    const handleMin = ()=>{
        const nextQuestion = currentQuestion - 1;
        if (nextQuestion >= 0 && nextQuestion < dataQuestions.length) {
            setCurrentQuestion(nextQuestion);
        }
    }

    const handlePlus = ()=>{
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion >= 0 && nextQuestion < dataQuestions.length) {
            setCurrentQuestion(nextQuestion);
        }
    }

    const updateTime = ()=> {
        // get new date
        const time = new Date();
        // set time units
        const __hours = time.getHours();
        const __minutes = time.getMinutes();
        const __seconds = time.getSeconds();
        // on hour chanage, update hours and shuffle state
        if( __hours !== hoursH) {
            const __hoursShuffle = !hoursShuffle;
            setHoursH(__hours);
            if(typeView === 'Kraepelin' ){
                setHoursShuffle(false);
            }
            else{
                setHoursShuffle(__hoursShuffle);
            }
        }
        // on minute chanage, update minutes and shuffle state
        if( __minutes !== minutesM) {
            const __minutesShuffle = !minutesShuffle;
            setMinutesM(__minutes);
            if(typeView === 'Kraepelin' ){
                setMinutesShuffle(false);
            }
            else{
                setMinutesShuffle(__minutesShuffle);
            }
        }
        // on second chanage, update seconds and shuffle state
        if( __seconds !== secondsS) {
            const __secondsShuffle = !secondsShuffle;
            setSecondsS(__seconds);
            if(typeView === 'Kraepelin' ){
                setSecondsShuffle(false);
            }
            else{
                setSecondsShuffle(__secondsShuffle);
            }
        }
    }

    /*const generateTable = (quest)=>{
      let allData = quest ?? [];
      let newDataQuest = [];
      let newDataAnswer = {};
      let newDataLabel = [{
          // question:'',
          question:[],
          questionDetailCode:'no',
          questionDetailId:'no',
      }];

      let aAnswer = [];

      if(allData.length) {
          allData.map((v, k) => {
              newDataLabel.push({
                  question: v['question'],
                  questionDetailCode: v.code,
                  questionDetailId: v.id,
              });
              aAnswer.push(v.answers);
              v.answers.map((nv, nk) => {
                  let labE = nv.label;
                  if (newDataAnswer[nv.label]) {
                  // if (newDataAnswer[nv.label] && newDataAnswer[nv.label].length) {
                      // newDataAnswer[nv.label].push(nv);
                  } else {
                      newDataAnswer[nv.label] = true;
                      // newDataAnswer[nv.label] = [nv];
                  }
              });
          });
          if (newDataLabel.length) {
              newDataQuest.push(newDataLabel);
          }

          let firstAnswer = Object.keys(newDataAnswer) ?? [];
          let checkLength = [];
          if (firstAnswer.length) {
              // let checkLength = [];

              firstAnswer.map(v => {
                  // checkLength.push(newDataAnswer[v].length);

                  let nConcat = [{
                  // checkLength.push({
                      question: v,
                      questionDetailCode: 'no',
                      questionDetailId: 'no'
                  // });
                  }];
                  aAnswer.map(vA=>{
                      let tP = true;
                      vA.map((vnA,vnAK)=>{
                          if(vnA.label === v){
                              tP = false;
                              nConcat.push(vnA);
                          }
                          if(vA.length-1 === vnAK && tP){
                              nConcat.push({
                                  code: 'no',
                                  label: v
                              })
                          }
                      })
                  })

                  newDataQuest.push(nConcat);




                  // let tPush = nConcat.concat(newDataAnswer[v]);
                  // newDataQuest.push(tPush);
              })

              // checkLength = [checkLength];
              // let _tmp = checkLength.concat(aAnswer);

              // let uniqueL = [...new Set(checkLength)];

              // if(uniqueL.length !== 1){
              //     setFindTable(true);
              // }
              // newDataQuest.push(_tmp);

          }

      }

      setQuestTable(newDataQuest);
    };*/


    React.useEffect(()=>{
        if(dataQuestions.length && loading === false && reload === false){
            // let nData = generateTable(dataQuestions)
            startTimer();
        }

    },[dataQuestions,loading,reload]);

    React.useEffect(()=>{
        if(countdown) {
            updateTime();
        }
    },[minutes,seconds,hours]);

    return (
        <div className='bdMultipleChoice'>
            <div className='flipClock'>
                <FlipUnitContainer
                    unit={'minutes'}
                    digit={minutes}
                    shuffle={minutesShuffle}
                />
                <FlipUnitContainer
                    unit={'seconds'}
                    digit={seconds}
                    shuffle={secondsShuffle}
                />
            </div>
            <div className='multipleChoice'>

                {loading ?
                    <div className='card-white'>
                        <CircularProgress color='primary'/>
                    </div>
                    :
                    dataQuestions.length && loading === false ?
                        <div className='row'>

                            <div className='question-section'>
                                <div className='question-count ml-2 text-center'>
                                    <span>Question {currentQuestion + 1}</span>/{dataQuestions.length}
                                </div>
                                {/* Quest not Table */}
                                {/*<p>locked: {dataQuestions[currentQuestion].locked === false ? 'false': 'true'}</p>*/}
                                {/*<p>example: {dataQuestions[currentQuestion].example === false ? 'false': 'true'}</p>*/}

                                {typeView !== 'Table' && typeView !== 'Kraepelin' && dataQuestions[currentQuestion].locked === false &&
                                dataQuestions[currentQuestion].question.map((v, k) => {
                                    if (v.question) {
                                        return (
                                            <p key={'des_' + k}
                                               className='question-text pb-3 m-2 col-12'
                                               dangerouslySetInnerHTML={{__html: v.question}}
                                            />
                                        )
                                    }
                                })
                                }
                                {typeView !== 'Table' && typeView !== 'Kraepelin' && dataQuestions[currentQuestion].locked === true &&

                                    <>{ kraepelinSession === currentQuestion && sessionsTimer[kraepelinSession] && dataQuestions[currentQuestion].example === true ?
                                        dataQuestions[currentQuestion].question.map((v, k) => {
                                            if (v.question) {
                                                return (
                                                    <p key={'des_' + k}
                                                       className='question-text pb-3 m-2 col-12'
                                                       dangerouslySetInnerHTML={{__html: v.question}}
                                                    />
                                                )
                                            }
                                        })
                                        : currentQuestion && !sessionsTimer[kraepelinSession] && dataQuestions[currentQuestion].example === false ?
                                        dataQuestions[currentQuestion].question.map((v, k) => {
                                            if (v.question) {
                                                return (
                                                    <p key={'des_' + k}
                                                       className='question-text pb-3 m-2 col-12'
                                                       dangerouslySetInnerHTML={{__html: v.question}}
                                                    />
                                                )
                                            }
                                        })
                                        : //dataQuestions[currentQuestion].example === true &&
                                          <p key={'des_example'}
                                             className='question-text pb-3 m-2 col-12'
                                            >
                                              {dataQuestions[currentQuestion].example === true ?
                                                  <>Waktu "Menghapal" / "Melihat contoh" telah habis.</>
                                                  :
                                                  <>Belum waktunya anda menjawab soal.</>
                                              }
                                          </p>
                                    }</>
                                    // <div
                                    //     className='question-text pb-3 m-2 '
                                    //     dangerouslySetInnerHTML={{__html: dataQuestions[currentQuestion].question}}
                                    // ></div>
                                }
                                {/* Image not Table */}
                                {(typeView !== 'Table' && dataQuestions[currentQuestion].images && dataQuestions[currentQuestion].images.length) ?
                                    <div className='row question-text pb-3 m-2 justify-content-center'>
                                        {dataQuestions[currentQuestion].images.map((v,k)=> {
                                            return (
                                                <img
                                                    alt={'img '+k}
                                                    src={process.env.REACT_APP_SERVER_IMAGE_URL + v}
                                                    className='m-2 p-2'
                                                    style={{
                                                        height:'200px',
                                                        // width: '200px'
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                    :null}
                                {kraepelin && typeView === 'Kraepelin' &&
                                <div key={'div_k'} className='question-count ml-2 text-center'
                                     style={{
                                         overflowX:'auto',
                                         maxWidth:'72vw',
                                         overflowY:'auto',
                                         maxHeight: '72vh'
                                     }}>
                                <table id={'tableK_'} key={'tableK_'} style={{border: '1px solid #000 !important', fontFamily: 'Courier New !important'}}>
                                    {
                                        dataQuestions[currentQuestion].question.map((q,qi)=>(//.question.map((questOption,k) => (
                                            <>
                                            <tr key={'trq_'+qi} id={'trq_'+qi}>
                                                {
                                                    q.question.map((qn,kqn)=>(
                                                        <>
                                                            <td key={'label_'+kqn}>{qn}</td>
                                                            <td key={'label_1'+kqn}></td>
                                                        </>
                                                    ))
                                                }
                                            </tr>
                                            <tr className='jwb' key={'tra_'+qi} id={'tra_'+qi}>
                                                {dataQuestions[currentQuestion].answers[qi] &&
                                                    dataQuestions[currentQuestion].answers[qi].map((answerOption,nq) => (
                                                        // answerOption.map(nA =>(
                                                        <>
                                                            <td key={'labelA_'+nq+answerOption.session}></td>
                                                            <td key={'labelA_1'+nq+answerOption.session} className='' style={{maxWidth:'55px'}}>{answerOption.answer && answerOption.session !== kraepelinSession ? answerOption.answer :
                                                                kraepelinSession === answerOption.session ?
                                                                <TextField
                                                                    className='input-group'
                                                                    variant='outlined'
                                                                    style={{
                                                                        width:'55px'
                                                                    }}
                                                                    type='text'
                                                                    id={'answer_'+answerOption.code}
                                                                    name={'answer'+answerOption.code}
                                                                    placeholder='.......................'
                                                                    onChange={(e) => {
                                                                        onChangeInput(e,qi, {
                                                                            currentQuestion:currentQuestion,
                                                                            columnId:nq,
                                                                            rowId:qi,
                                                                            code:answerOption.code
                                                                        });
                                                                        onAnswer(answerOption)
                                                                        // handleAnswerOptionClick(e,currentQuestion,kraepelinSession)
                                                                    }}
                                                                    // InputProps={{
                                                                    //     endAdornment: (
                                                                    //         <InputAdornment position='end'>
                                                                    //             <label >{nq+1}. </label>
                                                                    //         </InputAdornment>
                                                                    //     )
                                                                    // }}
                                                                    value={answerOption.answer}
                                                                    // fullWidth
                                                                /> : null
                                                            }</td>
                                                        </>
                                                        // ))
                                                    ))
                                                }
                                            </tr>
                                            </>
                                        ))
                                    }
                                    {/*<tr className='p-2' >*/}
                                    {/*    {*/}
                                    {/*        dataQuestions[currentQuestion].question[0].question.map((questOption,k) => (*/}
                                    {/*            <>*/}
                                    {/*                <td key={'label_'+k}>{questOption}</td>*/}
                                    {/*                <td key={'label_1'+k}></td>*/}
                                    {/*            </>*/}
                                    {/*        ))*/}
                                    {/*    }*/}
                                    {/*</tr>*/}
                                    {/*<tr className='jwb'>*/}
                                    {/*    {*/}
                                    {/*        dataQuestions?.[currentQuestion]?.answers.map((answerOption) => (*/}
                                    {/*            <>*/}
                                    {/*                <td></td>*/}
                                    {/*                <td>{answerOption.answer ? answerOption.answer : '-'}</td>*/}
                                    {/*            </>*/}
                                    {/*        ))*/}
                                    {/*    }*/}

                                    {/*</tr>*/}
                                </table>
                                    </div>
                                }


                            </div>

                            {/* Multiple Choice */}
                            {typeView === 'Multiple Choice' &&
                            <div className='answer-section row'>
                                {dataQuestions?.[currentQuestion].type !== 'input' ?
                                    dataQuestions?.[currentQuestion]?.answers.map((answerOption) => (
                                    <button
                                        key={answerOption.code}
                                        id={answerOption.code}
                                        className={'buttonMl col p-3 m-2 ' + (answerOption.selected === true ? 'btn' +
                                            ' active bg-success color-white' : '')}
                                        onClick={() => {
                                            handleAnswerOptionClick(answerOption.code)
                                            onAnswer(answerOption);
                                        }}>
                                        {answerOption.label}. {answerOption.answer}
                                        {answerOption.images && answerOption.images.length ?
                                            <div className='row question-text pb-3 m-2 justify-content-center'>
                                                {answerOption.images.map((v,k)=>{
                                                    return(
                                                        <img
                                                            alt={'img '+k}
                                                            src={process.env.REACT_APP_SERVER_IMAGE_URL+v}
                                                            className='m-2 p-2'
                                                            style={{
                                                                // height:'150px',
                                                                width:'150px'
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                            :null}
                                    </button>
                                )):
                                    dataQuestions?.[currentQuestion]?.answers.map((answerOption,k) => (
                                        <div className='text-center col'>
                                            {/*<div className='form-group'>*/}
                                        <TextField
                                            className='col-6 icon input-group'
                                            variant='outlined'
                                            type='text'
                                            id={'answer'+k}
                                            name={'answer'+k}
                                            placeholder='.......................'
                                            onChange={(e) => {
                                                onChangeInput(e,currentQuestion,k)
                                            }}
                                            // onKeyUp={
                                            //     (e) => {
                                            //         handleAnswerOptionClick(answerOption.code)
                                            //         onAnswer(answerOption)
                                            //     }
                                            // }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        {answerOption.answer && <label className='btn btn-outline-success' onClick={
                                                            (e) => {
                                                                handleAnswerOptionClick(answerOption.code)
                                                                onAnswer(answerOption)
                                                            }
                                                        }>
                                                            <i className='fa fa-check-circle' ></i>
                                                        </label>}
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={answerOption.answer}
                                            fullWidth
                                        />

                                        {/*</div>*/}
                                        </div>
                                    ))
                                }

                            </div>
                            }

                            {/* Image */}
                            {typeView === 'Image' &&
                            <div className='answer-section row'>
                                {dataQuestions?.[currentQuestion]?.answers.map((answerOption) => (
                                    <button
                                        key={answerOption.code}
                                        id={answerOption.code}
                                        className={'buttonMl col p-3 m-2 ' + (answerOption.selected === true ? 'btn' +
                                            ' active bg-success color-white' : '')}
                                        onClick={() => {
                                            handleAnswerOptionClick(answerOption.code)
                                            onAnswer(answerOption);
                                        }}>
                                        {answerOption.label}. {answerOption.answer}
                                        {answerOption.images && answerOption.images.length ?
                                            <div className='row question-text pb-3 m-2 justify-content-center'>
                                                {answerOption.images.map((v,k)=>{
                                                    return(
                                                        <img
                                                            alt={'img '+k}
                                                            src={process.env.REACT_APP_SERVER_IMAGE_URL+v}
                                                            className='m-2 p-2'
                                                            style={{
                                                                height:'150px',
                                                                width:'150px'
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        :null}
                                    </button>
                                ))}
                            </div>
                            }

                            {/* Table */}
                            {typeView === 'Kraepelin1' &&
                            <div className='answer-section row'>
                                <div className='container table-wrapper ' style={{
                                    overflowY:'auto',
                                    maxHeight:'25vh'
                                }}>
                                    {dataQuestions?.[currentQuestion]?.answers.map((answerOption,k) => (
                                    <div className='text-center col'>
                                        <TextField
                                            className='col-6 icon input-group'
                                            variant='outlined'
                                            type='text'
                                            id={'answer'+k}
                                            name={'answer'+k}
                                            placeholder='.......................'
                                            onChange={(e) => {
                                                onChangeInput(e,currentQuestion,k)
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <label >{k+1}. </label>
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={answerOption.answer}
                                            fullWidth
                                        />
                                    </div>
                                    ))}
                                </div>
                            </div>
                            }
                            {typeView === 'Table' &&
                            <div className='answer-section row'>
                                <div className='container table-wrapper'>
                                    <Table className='table-bordered' size='small' >
                                        <TableHead>
                                            <TableRow>
                                                {dataQuestions[currentQuestion].type === 'checkbox' &&
                                                    <TableCell align='center'></TableCell>
                                                }
                                                { typeView === 'Table' &&
                                                dataQuestions[currentQuestion].question.map(v=>{
                                                    if(v.question) {
                                                        return (
                                                            <TableCell align='center'><p
                                                        className='question-text pb-3 m-2 col-12'
                                                        dangerouslySetInnerHTML={{__html: v.question}}
                                                            /></TableCell>
                                                        )
                                                    }
                                                })}
                                                {dataQuestions[currentQuestion].type === 'radio' &&
                                                    <TableCell align='center'></TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataQuestions[currentQuestion].answers.map((answerOption,i)=> (
                                                <TableRow
                                                    // key={i}
                                                    // style={{ cursor: 'pointer' }}
                                                    // onDoubleClick={_ =>{
                                                    //     // onClick={_ => {
                                                    //     history.push({
                                                    //         pathname: 'master_theory/' + row.id,
                                                    //         state: row
                                                    //     })
                                                    // }}
                                                    key={answerOption.code}
                                                    // selected={v.selected}
                                                >
                                                    {dataQuestions[currentQuestion].type === 'checkbox' &&
                                                    <TableCell align='center'>
                                                        <p className='question-text pb-3 m-2 col-12'
                                                           dangerouslySetInnerHTML={{__html: answerOption.label}}
                                                        />
                                                    </TableCell>
                                                    }


                                                    { typeView === 'Table' &&
                                                    dataQuestions[currentQuestion].question.map(q=>{
                                                        if(q.question) {
                                                            return (
                                                                <TableCell align='center'>
                                                                {/*    <p className='question-text pb-3 m-2 col-12'*/}
                                                                {/*       dangerouslySetInnerHTML={{__html: v.label}}*/}
                                                                {/*    />*/}
                                                                {/*</TableCell>*/}
                                                                <Checkbox
                                                                    key={answerOption.code+q.code}
                                                                    id={answerOption.code+q.code}
                                                                    checked={answerOption.selected && answerOption['code'+answerOption.label] === q.code}
                                                                    // style={{justifyContent:'center'}}
                                                                    // className={'buttonMl col p-3 m-2 ' + (answerOption.selected === true ? 'btn' +
                                                                        // ' active bg-success color-white' : '')}
                                                                    onChange={() =>{
                                                                    // onClick={() => {
                                                                        let data = {
                                                                            question: q,
                                                                            answer: answerOption
                                                                        }
                                                                        onAnswer(data);
                                                                        handleAnswerOptionClick(answerOption.code)
                                                                    }}
                                                                ></Checkbox>
                                                                </TableCell>
                                                            )
                                                        }
                                                    })}

                                                    {dataQuestions[currentQuestion].type === 'radio' &&
                                                    <TableCell align='center'>
                                                        <p className='question-text pb-3 m-2 col-12'
                                                           dangerouslySetInnerHTML={{__html: answerOption.label}}
                                                        />
                                                    </TableCell>
                                                    }


                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                            }
                            {typeView === 'Table1' &&
                            <div className='answer-section row'>
                                <div className='container table-wrapper'>
                                    <Table className='table-bordered' size='small' >
                                        <TableHead>
                                            <TableRow>
                                                {questTable && questTable.length ?
                                                    questTable[0].map(v=> {
                                                        return <TableCell>{v['question']}</TableCell>
                                                    })

                                                    :<TableCell>n</TableCell>
                                                }
                                                {/*{dataQuestions[currentQuestion].type === 'checkbox' &&*/}
                                                {/*<TableCell className='py-5'>*/}
                                                {/*    <div*/}
                                                {/*        className='question-text pb-3 m-2 '*/}
                                                {/*        dangerouslySetInnerHTML={{__html: dataQuestions[currentQuestion]['question']}}*/}
                                                {/*    ></div>*/}
                                                {/*</TableCell>*/}
                                                {/*}*/}
                                                {/*{dataQuestions[currentQuestion].type === 'checkbox' &&*/}
                                                {/*    <TableCell align='center'>Aktivitas</TableCell>*/}
                                                {/*}*/}
                                                {/*{dataQuestions[currentQuestion].type === 'radio' &&*/}
                                                {/*    <TableCell align='center'>Pernyataan</TableCell>*/}
                                                {/*}*/}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {questTable[1] && questTable[1].length ?
                                                questTable.map((vk,lk)=>{
                                                    if(lk > 0){
                                                        let Next = `<>`;
                                                        vk.map((nv,k)=> {
                                                            if(k === 0 ){
                                                                Next = Next+`<TableCell>{nv.question}</TableCell>)`;
                                                            }
                                                            else if(nv.code !== 'no') {
                                                                Next = Next+`<TableCell align='center' className='py-5'>
                                                                    <button
                                                                        key={nv.code}
                                                                        id={nv.code}
                                                                        style={{justifyContent: 'center'}}
                                                                        className={'buttonMl col p-3 m-2 ' + (nv.selected === true ? 'btn' +
                                                                            ' active bg-success color-white' : '')}
                                                                        onClick={() => {
                                                                            handleAnswerOptionClick(nv.code)
                                                                            onAnswer(nv);
                                                                        }}>.
                                                                    </button>
                                                                </TableCell>`;
                                                            }
                                                            else{
                                                                Next = Next+`<TableCell></TableCell>`
                                                            }
                                                            if(vk.length-1 === k){

                                                            return Next;
                                                        }
                                                        })
                                                    }
                                                    // })
                                                })

                                                :<TableCell>n</TableCell>
                                            }
                                            {/*{dataQuestions?.[currentQuestion]?.answers.map((answerOption) => (*/}
                                            {/*    <TableCell align='center'>{answerOption.label}</TableCell>*/}
                                            {/*))}*/}
                                            {/*{dataQuestions[currentQuestion].type === 'checkbox' &&*/}
                                            {/*    <TableCell className='py-5'>*/}
                                            {/*        <div*/}
                                            {/*            className='question-text pb-3 m-2 '*/}
                                            {/*            dangerouslySetInnerHTML={{__html: dataQuestions[currentQuestion]['question']}}*/}
                                            {/*        ></div>*/}
                                            {/*    </TableCell>*/}
                                            {/*}*/}
                                            {/*{dataQuestions?.[currentQuestion]?.answers.map((answerOption) => (*/}
                                            {/*    <TableCell align='center' className='py-5'>*/}
                                            {/*        <button*/}
                                            {/*            key={answerOption.code}*/}
                                            {/*            id={answerOption.code}*/}
                                            {/*            style={{justifyContent:'center'}}*/}
                                            {/*            className={'buttonMl col p-3 m-2 ' + (answerOption.selected === true ? 'btn' +*/}
                                            {/*                ' active bg-success color-white' : '')}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                handleAnswerOptionClick(answerOption.code)*/}
                                            {/*                onAnswer(answerOption);*/}
                                            {/*            }}>.</button>*/}
                                            {/*        </TableCell>*/}
                                            {/*))}*/}
                                            {/*{dataQuestions[currentQuestion].type === 'radio' &&*/}
                                            {/*<TableCell className='py-5'>*/}
                                            {/*    <div*/}
                                            {/*        className='question-text pb-3 m-2 '*/}
                                            {/*        dangerouslySetInnerHTML={{__html: dataQuestions[currentQuestion].question}}*/}
                                            {/*    ></div>*/}
                                            {/*</TableCell>*/}
                                            {/*}*/}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                            }

                            {/* btn options */}
                            <div className='col-12 row justify-content-between m-0 mt-3'>
                                <button
                                    className='btn btn-secondary'
                                    onClick={() => {
                                        handleMin()
                                    }}
                                    disabled={currentQuestion=== 0}
                                    // hidden={currentQuestion=== 0}
                                >{currentQuestion ? `< `+  currentQuestion : ''} Q </button>
                                {currentQuestion === dataQuestions.length - 1 && nextToSave ?
                                    <button
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            handleDone()
                                        }}
                                    >{labelNext} </button> :
                                    <button
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            handlePlus()
                                        }}
                                    >Q {currentQuestion + 2 < dataQuestions.length + 1 ? currentQuestion + 2 + ` >` : ''} </button>
                                }
                            </div>
                        </div>
                    : null
                }


            </div>
        </div>
    );
}
