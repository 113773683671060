import React from 'react';
import TextField from "@material-ui/core/TextField";

export const MultipleUpload = ({
    keys,
    id,
    text,
    imagePut,
    onFileChange,
    handleUpload,
    handleDeleteUpload,
    disableBtn
}) => (
    <div className='row col-12 m-0 p-0'>
        {/*<div className='row'>*/}
            <TextField
                accept='.jpg,.jpeg,.png'
                type='file'
                id={"image" + keys}
                name={"image" + keys}
                className='avatar d-none'
                onChange={(e)=>onFileChange(e,keys)}
                fullWidth
                hidden
            />
            {imagePut[keys] && imagePut[keys].length ?
                <><div key={'lab_'+keys} className='form-group col-12 m-0 py-2'>
                    {text.label_upload}:
                </div>
                    {imagePut[keys].map((v,k)=> {
                    return <div className='col-12 row m-0 p-0' key={'plus'+k+keys}>
                        <div key={'lab_'+k+keys} className='form-group col-9 m-0 p-0 py-2'>
                            <label className='text-muted'>{k+1}. { v.name}</label>
                        </div>
                        <div key={'btn_'+k+keys} className='form-group col-3 m-0 py-2 text-center'>
                            {(imagePut[keys].length -1 === k && imagePut[keys].length >= 1) &&
                            <label
                                key={'plus'+k+keys}
                                className='btn btn-success'
                                htmlFor={"image" + keys}
                                // for={"image" + keys}
                                id="upload-image"
                                hidden={disableBtn}
                            >+</label>}
                            <label
                                key={'min'+k+keys}
                                className='btn btn-danger'
                                onClick={() => handleDeleteUpload(k, keys)}
                                hidden={disableBtn}
                            >-</label>
                        </div>
                    </div>
                })}</>:<div className='col-12 row m-0 p-0'>
                    <div key={'lab_'+keys} className='form-group col-9 m-0 p-0 py-2'>
                        <label className='text-muted'>{text.label_upload}</label>
                    </div>
                    <div key={'btn_'+keys} className='form-group col-3 m-0 py-2 text-center'>
                        <label
                            key={'plus'+keys}
                            className='btn btn-success'
                            htmlFor={"image" + keys}
                            // for={"image" + keys}
                            id="upload-image"
                            hidden={disableBtn}
                        >+</label>
                    </div>
                </div>
            }
        {/*</div>*/}
    </div>
);