import React,{Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Copyright from '../../../presentational/copyright';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import Logo from '../../../../images/logo-black.png';
import Image from '../../../../images/reset.png';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import AuthHelper from "../../../../libraries/auth-helper";
import Api from "../../../../libraries/api";
import {bindActionCreators} from 'redux';

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Pass extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            showPassword: false,
            showPasswordConfirm: false,

            errors: [],
            password: '',
            passwordConfirm: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'PLPT - Forgot';
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleSubmit = e => {

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        if (this.state.password !== this.state.passwordConfirm) {

            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        passwordConfirm: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loading: true,
            }
        );

        let params = {

            email: this.state.password,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,

        };

        Api.post('/login', params).then(resp => {

            AuthHelper.setLogin(resp.data);

            AuthHelper.setProfile(resp.data);

            let profile = resp.data;

            let user = JSON.parse(JSON.stringify(profile));

            this.props.setProfile(user);

            history.push('/reservation');

        }).catch(err => {

            this.setState({
                    errors: err,
                    loading: false
                }
            );

        });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };


    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    render() {

        return (
            <main className='success'>
                <section className='my-auto'>
                    <div className='container text-center'>
                        <img src={Logo} alt='logo' className='img-logo' />
                        <img src={Image} alt='logo' className='pt-3 d-block mx-auto mb-3' />
                        <p className='text-big text-blue'>Mengganti Kata Sandi Sukses</p>
                        <p className='text-center py-4 mb-0'>Sekarang Anda dapat menggunakan kata sandi baru untuk masuk ke akun Anda.</p>
                    </div>
                </section>
                <footer>
                    <p className='text-center absolute-bottom'><Copyright /></p>
                </footer>

                <ToastContainer autoClose={false} />
            </main>
        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Pass);