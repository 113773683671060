import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import _ from "lodash";

import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";

export const Selector = ({
  title,
  none,
  item,
  selected,
  className,
  header = true,
  variant = "outlined",
  vertical = false,
  setWarn,
}) => {
  const [state, setState] = React.useState("");
  const handleChange = (event) => {
    setState(event.target.value);
    selected(event.target.value);
  };

  return (
    <div className={vertical ? "d-inline-flex align-items-center" : ""}>
      <div className="pb-1 pr-2" style={{ fontSize: 14 }}>
        <nobr>{header && title}</nobr>
      </div>
      <FormControl
        style={{
          // padding: 10,
          borderRadius: 5,
          border: setWarn ? `1px solid red` : ``,
          maxWidth: vertical ? 200 : 170,
        }}
        variant={variant}
        className={className}
      >
        {/* {header && state === "" && (
          <InputLabel style={{ fontSize: 16 }}>{title}</InputLabel>
        )} */}
        <Select
          style={{
            borderRadius: 5,
            padding: 0,
            border: setWarn ? `1px solid red` : ``,

            height: 35,
            maxWidth: 170,
            minWidth: 130,
          }}
          value={state}
          onChange={handleChange}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
        >
          <MenuItem value=''>
            All
              </MenuItem>
          {!_.isEmpty(item) &&
            item.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const CustomDateRange = ({
  onChange,
  value,
  headerPos = "top",
  vertical = false,
  title = "Tanggal",
  titleStyle,
  reset,
  setWarn,
}) => {
  const [isChange, setChange] = useState(null);

  React.useEffect(() => {
    if (!value) {
      setChange(null);
    }
  }, [value]);
  return (
    <div className={vertical ? "d-inline-flex align-items-center" : ""}>
      {headerPos === "top" && (
        <div className="pb-1 pr-2" style={{ fontSize: 14, ...titleStyle }}>
          {title}
        </div>
      )}
      <DateRangePicker
        onCallback={(startDate, endDate) => {
          setChange([
            moment(startDate._d).format("DD/MM/YY"),
            moment(endDate._d).format("DD/MM/YY"),
          ]);
          onChange(
            moment(startDate._d).format("YYYY-MM-DD"),
            moment(endDate._d).format("YYYY-MM-DD")
          );
        }}
        initialSettings={{ startDate: "1/1/2021", endDate: "1/1/2021" }}
      // value={value}
      >
        <button
          className="btn bg-white p-2"
          style={{
            borderRadius: 5,
            border: setWarn ? `1px solid red` : `1px solid lightgrey`,
            display: "d-inline-flex",
            fontSize: 12,
            color: "dimgrey",
            minWidth: 120,
          }}
        >
          {!isChange ? `Mulai - Akhir` : `${isChange[0]} - ${isChange[1]}`}
        </button>
      </DateRangePicker>
    </div>
  );
};

const default_data = [
  {
    text: "Test1",
    value: "test",
  },
  {
    text: "Test1",
    value: "test",
  },
];

export const MultipleSelect = ({
  data = default_data,
  title = "default title",
  vertical = false,
  titleStyle,
}) => (
    <div className={vertical ? "d-inline-flex align-items-center" : ""}>
      <div className="pb-1 pr-2" style={{ fontSize: 14, ...titleStyle }}>
        {title}
      </div>

      <Dropdown
        style={{ minWidth: 200 }}
        //   placeholder="State"
        // fluid
        multiple
        search
        selection
        options={data}
        onChange={(event, { value, options, key, text }) => null}
      />
    </div>
  );



export const SearchInput = ({ onSubmit, placeholder, title }) => {
  const [state, setState] = useState("");
  return (
    <div
      className="d-inline-flex align-items-center mr-2 bg-white"
      style={{
        borderRadius: 5,
        border: `1px solid lightgrey`,
        padding: 5,
      }}
    >
      <input
        style={{
          minWidth: window.innerWidth > 375 ? 230 : 200,
          outline: "none",
          border: 0,
          marginLeft: 5,
        }}

        onKeyPress={e => {
          if (e.key === 'Enter') {
            onSubmit(state)
          }
        }}
        onChange={(e) => {
          e.preventDefault();
          setState(e.target.value);
        }}
        placeholder={placeholder}
      />

      <IconSearch
        onClick={(_) => onSubmit(state)}
      />

    </div>
  );
};

const IconSearch = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className="bi bi-search"
    fill="grey"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
    />
    <path
      fillRule="evenodd"
      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
    />
  </svg>
);
