import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import {toast, ToastContainer} from 'react-toastify';
import {bindActionCreators} from 'redux';
import Api from '../../../../libraries/api';
import languange from "../../../../libraries/label-lang";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AuthHelper from "../../../../libraries/auth-helper";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";

require('dotenv').config();

class UsersDetail extends Component {
    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loadingButton: false,
            // errors: [],
            disableUpdate: true,
            user: '',

            loading: false,
            showPassword: false,
            showPasswordConfirm: false,
            errors: {},
            limit:1,
            page:1,

            id: 1,
            name:'',
            description:'',
            image:'',


            imageFile: {},
            imagePut: {}
        }
    }

    componentDidMount() {
        document.title = 'CMS - Detail account';

        let usersLogin = AuthHelper.getProfile();
        const id = Number(this.props.match.params.id);

        let setData = {};

        Object.keys(usersLogin).map(k =>{
            setData[k]= usersLogin[k];
            if(k === 'birth_date' && usersLogin[k]){
                setData['bod'] = new Date(usersLogin[k]);
            }
            return k;
        });

        this.setState({
            loading: true,
            id: id,
            user: usersLogin,
            ...setData
        },()=>{
            this.setState({
                loading: false
            });
        });

    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
      this.setState({
          disableUpdate: false,
          error:{},
      });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? true : false,

        })

    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        if (this.state.password !== this.state.passwordConfirm) {

            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        passwordConfirm: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );


        let params = {
            nip: this.state.nip,
            name: this.state.name,
            gender:this.state.gender,
            address:this.state.address,
            phone: this.state.phone,
            birth_place: this.state.birth_place,
            birth_date: this.state.bod !== null ? this.state.bod.getFullYear() + '-' + appendLeadingZeroes(this.state.bod.getMonth() + 1) + '-' + appendLeadingZeroes(this.state.bod.getDate()): '',
            age: '',
            religion: this.state.religion,
            citizenship: this.state.citizenship,
            tribes: this.state.tribes,

            username: this.state.username,
            password: this.state.password,
            access: this.state.access,
            role: this.state.role,
        };

        Api.put('/users/me/'+this.state.id,params).then(resp => {

            if(resp){
                AuthHelper.setProfile(resp);
            }

            this.setState({
                loadingButton: false,
            });

            this.handleGo('/');
            this.showMessage(true, 'Account successfully updated!');

        }).catch(err => {

            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });

            this.setState({
                    errors: errors ? errors : {},
                    loadingButton: false
                }
            );

            this.showMessage(false, err.message);

        });


    };


    // handle image upload
    onFileChange = (e) =>{
        // setErrText('');

        let temp = _.get(e,'target.files[0]',false);

        if(temp){
            if(e.target.files[0].size > 999999){
                this.showMessage(false,'This file is too big. Maximum size is 1MB.',true);
            }else if(
                e.target.files[0].name.toLowerCase().search('.jpeg') === -1 &&
                e.target.files[0].name.toLowerCase().search('.jpg') === -1  &&
                e.target.files[0].name.toLowerCase().search('.png') === -1){

                this.showMessage(false,'This file is invalid. Only accept .jpg, .jpeg and .png',true);

            }else{
                let __image_file = e.target.files[0];

                let upload_image = [__image_file];

                this.setState({
                    imageFile: __image_file
                },()=>{
                    this.handleUpload(upload_image);
                });

            }
        }
    };

    handleUpload = (e) => {
        var formData = new FormData();
        for (const key of Object.keys(e)) {
            formData.append('imgCollection', e[key])
        }

        Api.putFile('/uploads',{
            method: 'POST',
            body: formData
        }).then(resp => {
            if(resp.images && resp.images.length){
                let nData = {};

                resp.images.map(v=>{
                    nData = {name:v};
                    return v;
                });

                this.setState({
                    imagePut: nData
                })
                this.showMessage(true,resp?.message ?? 'Connection failed, please try again to upload.')
            }
            else {
                this.showMessage(false,resp?.message ?? 'Connection failed, please try again to upload.')
            }

        }).catch(err => {
            this.showMessage(false,err?.message ?? 'Connection failed, please try again to upload.')

        });
    }

    handleDeleteUpload = () =>{
        // let sData = this.state.imageFile;

        this.setState({
            imageFile: {},
            imagePut: {},
            // listAnswers: dataAns
        },()=>{});
    }


    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{text.label_company}</h2>
                            { this.state.loading === true ? <i className='fa fa-spinner fa-spin' /> :
                                <form name='view' id='viewUsers' className='col-12 row' noValidate>
                                    <div className='col-6'>
                                        {this.state.disableUpdate === true &&
                                        <div className='form-group'>
                                        <img
                                            alt={'img company'}
                                            src={process.env.REACT_APP_SERVER_IMAGE_URL+this.state.images}
                                            className='m-2 p-2'
                                            style={{
                                                width:'150px'
                                            }}
                                        />
                                        </div>
                                        }
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='name'
                                                name='name'
                                                label={text.label_company}
                                                placeholder={text.label_company}
                                                onChange={(e) => this.handleChange(e, 'name')}
                                                value={this.state.name}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            />
                                            {this.validator.message('name', this.state.name, 'required')}
                                            <div className='text-danger'>{this.state.errors.name}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='address'
                                                name='address'
                                                label={text.label_descriptions}
                                                placeholder={text.label_descriptions}
                                                onChange={(e) => this.handleChange(e, 'address')}
                                                value={this.state.description}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            >
                                            </TextField>
                                            {this.validator.message('address', this.state.address, 'required')}
                                            <div className='text-danger'>{this.state.errors.address}</div>
                                        </div>
                                        {this.state.disableUpdate === false && <div className='form-group'>
                                            <TextField
                                                accept='.jpg,.jpeg,.png'
                                                type='file'
                                                id={"image"}
                                                name={"image"}
                                                className='avatar d-none'
                                                onChange={(e)=>this.onFileChange(e)}
                                                fullWidth
                                                hidden
                                            />
                                            <div key={'lab_'} className='form-group col-12 m-0 py-2'>
                                                {text.label_upload}:
                                            </div>
                                            {this.state.imagePut &&
                                                <div className='col-12 row m-0 p-0' key={'plus'}>
                                                    { this.state.imagePut?.name ?
                                                        <div key={'lab_'} className='form-group col-9 m-0 p-0 py-2'>
                                                            <label className='text-muted'>{ this.state.imagePut?.name}</label>
                                                        </div>:
                                                        <div key={'lab_'} className='form-group col-9 m-0 p-0 py-2'>
                                                            <label className='text-muted'></label>
                                                        </div>
                                                    }
                                                    <div key={'btn_'} className='form-group col-3 m-0 py-2 text-center'>
                                                        {this.state.imagePut?.name ?
                                                            <label
                                                                key={'min'}
                                                                className='btn btn-danger'
                                                                onClick={() => this.handleDeleteUpload()}
                                                                hidden={false}
                                                            >-</label>
                                                            :
                                                            <label
                                                                key={'plus'}
                                                                className='btn btn-success'
                                                                htmlFor={"image"}
                                                                id="upload-image"
                                                                hidden={false}
                                                            >+</label>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>}
                                    </div>
                                    <div className='col-12 text-center mt-4'>
                                        <Button
                                            variant='contained'
                                            className='mr-3'
                                            onClick={() => this.handleGo('/')}
                                        >
                                            {text.button_back}
                                        </Button>

                                        {this.state.disableUpdate ?
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className=''
                                                onClick={this.enableUpdate}
                                                disabled={this.state.loadingButton}
                                            >
                                                {text.button_edit} {this.state.loadingButton &&
                                            <i className='fa fa-spinner fa-spin'> </i>}
                                            </Button>
                                            :
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className=''
                                                onClick={this.handleSubmit}
                                                disabled={this.state.loadingButton}
                                            >
                                                {text.label_save} {this.state.loadingButton &&
                                            <i className='fa fa-spinner fa-spin'> </i>}
                                            </Button>
                                        }
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDetail);


function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}