import React,{Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SimpleReactValidator from 'simple-react-validator';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import {Link, withRouter} from 'react-router-dom';
import Api from '../../../../libraries/api';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";
import AuthHelper from "../../../../libraries/auth-helper";
import templateId from "../../../../libraries/lang-id";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

SimpleReactValidator.addLocale('indo', templateId);


class UsersAdd extends Component {

    constructor(props) {

        super(props);


        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );


        this.state = {
            loading: false,
            errors: {},
            limit:1,
            page:1,

            nip: '',
            name: '',
            gender:'',
            listGender:[{
                name: '--- Gender ---',
                value: '',
            },{
                name: 'Laki-laki',
                value: 'Laki-laki',
            },{
                name: 'Perempuan',
                value: 'Perempuan',
            }],
            address:'',
            phone: '',
            birth_place: '',
            birth_date: '',
            bod: null,
            // age: '',
            religion: '',
            citizenship:'',
            tribes:'',
            username: '',
            password: '',
            passwordConfirm:'',
            showPassword: false,
            showPasswordConfirm: false,
            access: true,
            role:'',
            listRules:[{
                name: '--- Access ---',
                code: ''
            }],
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClickShowPasswordConfirm = this.handleClickShowPasswordConfirm.bind(this);
    }

    componentDidMount(){
        document.title = 'CMS - Add Account';
        this.__loadRules();
    };

    __loadRules = ()=>{
        const usersLogin = AuthHelper.getProfile();
        this.setState({
            loading: true,
            user: usersLogin,
        });

        if(usersLogin && usersLogin.role && (usersLogin.role === 1 || usersLogin.role === 2)) {

            Api.get('/roles').then(res => {
                let dataRoles =[{
                    name: '--- Access ---',
                    code: ''
                }];
                let response = res.result.data;

                response.map(v=>{
                    if(v.code){
                        dataRoles.push(v);
                    }
                    return v;
                })
                this.setState({
                    listRules: dataRoles,
                    loading: false,
                })
            }).catch((err) => {
                this.setState({
                    loading: false,
                });
                this.showMessage(false, err && err.message ? err.message : 'address not found!');
            });
        }
        else {
            this.setState({ loading: false, });
            this.showMessage(false, 'You can`t be access this pages!');
        }
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };

    handleChange (e, prop){
        this.setState({
             [prop]: e && e.target && e.target.value ? e.target.value : ''
        });
    };

    handleChecked = name => e => {
        this.setState({
            [name]: e.target.checked ? true : false,
        })
    };

    handleDate (date, prop){
        this.setState({
            [prop]: date
        })
    };

    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );
            return false;
        }
        if (this.state.password !== this.state.passwordConfirm) {
            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        passwordConfirm: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );
            return false;

        }
        this.setState({
                errors: {},
                loading: true,
            }
        );
        let params = {
            nip: this.state.nip,
            name: this.state.name,
            gender:this.state.gender,
            address:this.state.address,
            phone: this.state.phone,
            birth_place: this.state.birth_place,
            birth_date: this.state.bod !== null ? this.state.bod.getFullYear() + '-' + appendLeadingZeroes(this.state.bod.getMonth() + 1) + '-' + appendLeadingZeroes(this.state.bod.getDate()): '',
            age: this.state.age,
            religion: this.state.religion,
            citizenship: this.state.citizenship,
            tribes: this.state.tribes,

            username: this.state.username,
            password: this.state.password,
            access: this.state.access,
            role: this.state.role,
        };

        Api.post('/users/add', params).then(resp => {
            this.setState({
                    loading: false,
                }
            );
            this.setState({
                loading: false,
            });

            this.handleGo('/manage_users');
            this.showMessage(true, 'User successfully added');


        }).catch(err => {
            // let errors = [];
            // if(err.errors) {
            //     errors = err.errors;
            //     _.forEach(errors, function(v,k){
            //         v = v[0];
            //     });
            // }
            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });
            this.setState({
                    errors: errors ? errors : {},
                    loading: false
                }
            );
            this.showMessage(false, err.message);
        });

    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/manage_users' >{text.label_manage_users}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.button_add}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{text.button_add} {text.label_account}</h2>
                            <form name='add' id='addUsers' className='col-12 row' noValidate>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='nip'
                                            name='nip'
                                            label={text.label_nip}
                                            placeholder={text.label_nip}
                                            onChange={(e) => this.handleChange(e, 'nip')}
                                            value={this.state.nip}
                                            fullWidth
                                        />
                                        {this.validator.message('nip', this.state.nip, 'required')}
                                        <div className='text-danger'>{this.state.errors.nip}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='name'
                                            name='name'
                                            label={text.label_name}
                                            placeholder={text.label_name}
                                            onChange={(e) => this.handleChange(e, 'name')}
                                            value={this.state.name}
                                            fullWidth
                                        />
                                        {this.validator.message('name', this.state.name, 'required')}
                                        <div className='text-danger'>{this.state.errors.name}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            select
                                            id='gender'
                                            name='gender'
                                            label={text.label_gender}
                                            onChange={(e) => this.handleChange(e, 'gender')}
                                            value={this.state.gender}
                                            fullWidth
                                        >
                                            {this.state.listGender.map((data, idl) => (
                                                <MenuItem key={idl} value={data.value}>
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.validator.message('gender', this.state.gender, 'required')}
                                        <div className='text-danger'>{this.state.errors.gender}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='address'
                                            name='address'
                                            label={text.label_address}
                                            placeholder={text.label_address}
                                            onChange={(e) => this.handleChange(e, 'address')}
                                            value={this.state.address}
                                            fullWidth
                                            multiline
                                            rows={4}
                                        >
                                        </TextField>
                                        {this.validator.message('address', this.state.address, 'required')}
                                        <div className='text-danger'>{this.state.errors.address}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='phone'
                                            name='phone'
                                            label={text.label_phone}
                                            placeholder={text.label_phone}
                                            onChange={(e) => this.handleChange(e, 'phone')}
                                            value={this.state.phone}
                                            fullWidth
                                        />
                                        <div className='text-danger'>{this.state.errors.phone && this.state.errors.phone}</div>
                                    </div>
                                    <div className='row'>
                                    <div className='form-group col-md-6'>
                                        <TextField
                                            type='text'
                                            id='birth_place'
                                            name='birth_place'
                                            label={text.label_birth_place}
                                            placeholder={text.label_birth_place}
                                            onChange={(e) => this.handleChange(e, 'birth_place')}
                                            value={this.state.birth_place}
                                            fullWidth
                                        />
                                        <div className='text-danger'>{this.state.errors.birth_place && this.state.errors.birth_place}</div>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} className="col-md-12">
                                            <DatePicker
                                                className='col-12'
                                                label={text.label_birth_date}
                                                value={this.state.bod}
                                                onChange={(date) => this.handleDate(date, 'bod')}
                                                format={'yyyy-MM-dd'}
                                                cancelLabel={text.button_back}
                                                // inputVariant=""
                                                size='small'
                                            />
                                        </MuiPickersUtilsProvider>
                                        <div className='text-danger'>{this.state.errors.bod}</div>
                                    </div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            id='religion'
                                            name='religion'
                                            label={text.label_religion}
                                            placeholder={text.label_religion}
                                            onChange={(e) => this.handleChange(e, 'religion')}
                                            value={this.state.religion}
                                            fullWidth
                                        >
                                        </TextField>
                                        <div className='text-danger'>{this.state.errors.religion}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            id='citizenship'
                                            name='citizenship'
                                            label={text.label_citizenship}
                                            placeholder={text.label_citizenship}
                                            onChange={(e) => this.handleChange(e, 'citizenship')}
                                            value={this.state.citizenship}
                                            fullWidth
                                        >
                                        </TextField>
                                        <div className='text-danger'>{this.state.errors.citizenship}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            id='tribes'
                                            name='tribes'
                                            label={text.label_tribes}
                                            placeholder={text.label_tribes}
                                            onChange={(e) => this.handleChange(e, 'tribes')}
                                            value={this.state.tribes}
                                            fullWidth
                                        >
                                        </TextField>
                                        {/*{this.validator.message('tribes', this.state.tribes, 'required')}*/}
                                        <div className='text-danger'>{this.state.errors.tribes}</div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='form-group col-8'>
                                            <TextField
                                                select
                                                id='role'
                                                name='role'
                                                label={text.label_rules}
                                                onChange={(e) => this.handleChange(e, 'role')}
                                                value={this.state.role}
                                                fullWidth
                                            >
                                                {this.state.listRules.map((option, idx) => (
                                                    <MenuItem key={idx} value={option.code}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {this.validator.message('role', this.state.role, 'required')}
                                            <div className='text-danger'>{this.state.errors.role}</div>
                                        </div>
                                        <div className='form-group col-4'>
                                            <FormControl component='fieldset' className='col-12'>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <AntSwitch
                                                                checked={this.state.access}
                                                                onChange={this.handleChecked('access')}
                                                                name="access"
                                                            />
                                                        }
                                                        className=''
                                                        label={'Member '+ text.label_status}
                                                        labelPlacement="top"
                                                    />
                                                    {this.validator.message('access', this.state.access, 'required')}
                                                    <div className='text-left text-alert'>{this.state.errors.access}</div>
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='username'
                                            name='username'
                                            label={text.label_username}
                                            placeholder={text.label_username}
                                            onChange={(e) => this.handleChange(e, 'username')}
                                            value={this.state.username}
                                            fullWidth
                                        />
                                        {this.validator.message('username', this.state.username, 'required')}
                                        <div className='text-danger'>{this.state.errors.username}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            id='adornment-password'
                                            name='password'
                                            label={text.label_password}
                                            placeholder={text.label_password}
                                            onChange={(e) => this.handleChange(e,'password')}
                                            value={this.state.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleClickShowPassword}
                                                            className='pass'
                                                        >
                                                            {this.state.showPassword ? <i className='fas fa-eye'> </i> :
                                                                <i className='fas fa-eye-slash'> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {this.validator.message('password', this.state.password, 'required')}
                                        <div className='text-danger'>{this.state.errors.password}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                            id='adornment-password'
                                            name='passwordConfirm'
                                            label={text.label_password_confirm}
                                            placeholder={text.label_password_confirm}
                                            onChange={(e) => this.handleChange(e,'passwordConfirm')}
                                            value={this.state.passwordConfirm}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleClickShowPasswordConfirm}
                                                            className='pass'
                                                        >
                                                            {this.state.showPasswordConfirm ? <i className='fas fa-eye'> </i> :
                                                                <i className='fas fa-eye-slash'> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                        {this.validator.message('passwordConfirm', this.state.passwordConfirm, 'required')}
                                        <div className='text-danger'>{this.state.errors.passwordConfirm}</div>
                                    </div>
                                </div>

                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/manage_users')}
                                    >
                                        {text.button_back}
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className=''
                                        onClick={this.handleSubmit}
                                        disabled={this.state.loading}
                                    >
                                        {text.label_save} {this.state.loading &&
                                    <i className='fa fa-spinner fa-spin'> </i>}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersAdd));

function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);
