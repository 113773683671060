import React,{Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SimpleReactValidator from 'simple-react-validator';
import {history} from '../../../../shared/configure-store';
import {toast, ToastContainer} from 'react-toastify';
import {Link, withRouter} from 'react-router-dom';
import Api from '../../../../libraries/api';
import {bindActionCreators} from "redux";
import {RootActions} from "../../../../shared/root-action";
import {connect} from "react-redux";
import languange from "../../../../libraries/label-lang";
import AuthHelper from "../../../../libraries/auth-helper";
import templateId from "../../../../libraries/lang-id";

SimpleReactValidator.addLocale('indo', templateId);


class CategoryAdd extends Component {

    constructor(props) {

        super(props);


        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );


        this.state = {
            loadingButton: true,
            showButton: false,

            errors: [],
            listCategory:[],
            categoryId:'',
            name: '',
            descriptions: '',

        }
    }

    componentDidMount(){

        document.title = 'PLPT - Add Category';

        Api.post('/categories',{}).then(res => {
            let dataCategory = [{
                name: '--- Categories ---',
                id: ''
            }];
            let response = res.result.data;

            response.map(v => {
                if (v.id) {
                    dataCategory.push(v);
                }
                return v;
            })
            this.setState({
                listCategory: dataCategory,
                loadingButton: false,
            });
        }).catch(err=>{
            this.showMessage(false, err.message);
            this.setState({
                loadingButton: false,
            })
        });
    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleGo  = (link) => {
        history.push(link);
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );

        let params = {
            name: this.state.name,
            categoryId: this.state.categoryId,
            descriptions: this.state.descriptions
        };

        Api.post('/subcategories/add',params).then(resp => {

            this.setState({
                loadingButton: false,
            });

            this.handleGo('/master_subcategory');
            this.showMessage(true, 'Category successfully added');

        }).catch(err => {

            this.setState({
                    // errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, err.message);//'Invalid/duplicate data');

        });


    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (

            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item'><Link to='/master_subcategory' >{text.label_master_subcategory}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{text.button_add}</li>
                    </ol>
                </nav>

                <div className='row'>
                    <div className='col-lg-8 col-xl-7'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{text.button_add} {text.label_subcategory}</h2>
                            <form name='add' id='addProduct' className='row' noValidate>
                                <div className='col-12 mb-2'>
                                    <div className='form-group'>
                                        <TextField
                                            select
                                            id='categoryId'
                                            name='categoryId'
                                            label={text.label_category}
                                            onChange={(e) => this.handleChange(e, 'categoryId')}
                                            value={this.state.categoryId}
                                            fullWidth
                                        >
                                            {this.state.listCategory.map((data, idl) => (
                                                <MenuItem key={idl} value={data.id}>
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {this.validator.message('categoryId', this.state.categoryId, 'required')}
                                        <div className='text-danger'>{this.state.errors.categoryId}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='name'
                                            name='name'
                                            label={text.label_subcategory_name}
                                            placeholder={text.label_subcategory_name}
                                            onChange={(e) => this.handleChange(e, 'name')}
                                            value={this.state.name}
                                            fullWidth
                                        />
                                        {this.validator.message('name', this.state.name, 'required')}
                                        <div className='text-danger'>{this.state.errors.name}</div>
                                    </div>
                                    <div className='form-group'>
                                        <TextField
                                            type='text'
                                            id='descriptions'
                                            name='descriptions'
                                            label={text.label_subcategory_descriptions}
                                            placeholder={text.label_subcategory_descriptions}
                                            onChange={(e) => this.handleChange(e, 'descriptions')}
                                            value={this.state.descriptions}
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                        {/*{this.validator.message('descriptions', this.state.descriptions, 'required')}*/}
                                        <div className='text-danger'>{this.state.errors.descriptions}</div>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-4'>
                                    <Button
                                        variant='contained'
                                        className='mr-3'
                                        onClick={() => this.handleGo('/master_subcategory')}
                                    >
                                        {text.button_back}
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className=''
                                        onClick={this.handleSubmit}
                                        disabled={this.state.loadingButton}
                                    >
                                        {text.label_save} {this.state.loadingButton &&
                                    <i className='fa fa-spinner fa-spin'> </i>}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryAdd));