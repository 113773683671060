import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './shared/configure-store';

import Navbar from "./components/container/cms/templates/global";
import Dashboard from './components/container/cms/routes';
import Login from './components/container/cms/login';
import Register from './components/container/cms/register';
import Forgot from './components/container/cms/forgot';
import ForgotSuccess from "./components/container/cms/forgot/success";
import ForgotReset from "./components/container/cms/forgot/reset";
import Pass from "./components/container/cms/forgot/pass";
import NotFound from './components/container/cms/not-found';

import { PrivateRoute, RedirectRoute } from './libraries/route-handling';

const Routes = ({store}) => (

    <Provider store={store}>

        <ConnectedRouter history={history}>
            <Navbar />
            {

                <Switch>

                    <RedirectRoute path="/login" component={Login}/>

                    <RedirectRoute path="/register" component={Register}/>

                    <RedirectRoute path="/forgot" component={Forgot}/>
                    <RedirectRoute path="/forgot-success" component={ForgotSuccess}/>
                    <RedirectRoute path="/forgot-reset" component={ForgotReset}/>
                    <RedirectRoute path="/reset-success" component={Pass}/>

                    <PrivateRoute path="/" component={Dashboard}/>

                    <Route component={NotFound}/>

                </Switch>

            }

        </ConnectedRouter>

    </Provider>

);

export default Routes;