import React,{Component} from 'react';
import Button from '@material-ui/core/Button';
import {history} from '../../../../shared/configure-store';
import {connect} from 'react-redux';
import {RootActions} from '../../../../shared/root-action';
import {toast, ToastContainer} from 'react-toastify';
import {bindActionCreators} from 'redux';
import Api from '../../../../libraries/api';
import languange from "../../../../libraries/label-lang";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import AuthHelper from "../../../../libraries/auth-helper";
import SimpleReactValidator from "simple-react-validator";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

require('dotenv').config();

class UsersDetail extends Component {
    constructor(props) {

        super(props);

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );

        this.state = {
            loadingButton: false,
            // errors: [],
            disableUpdate: true,
            user: '',

            loading: false,
            showPassword: false,
            showPasswordConfirm: false,
            errors: {},
            limit:1,
            page:1,

            id: '',
            nip: '',
            name: '',
            gender:'',
            listGender:[{
                name: '--- Gender ---',
                value: '',
            },{
                name: 'Laki-laki',
                value: 'Laki-laki',
            },{
                name: 'Perempuan',
                value: 'Perempuan',
            }],
            address:'',
            phone: '',
            birth_place: '',
            birth_date: '',
            bod: null,
            religion: '',
            citizenship:'',
            tribes:'',
            username: '',
            password: '',
            passwordConfirm:'',
            access: true,
            role:'',
            listRules:[{
                name: '--- Access ---',
                code: ''
            }],
        }
    }

    componentDidMount() {
        document.title = 'CMS - Detail account';

        let usersLogin = AuthHelper.getProfile();
        const id = Number(this.props.match.params.id);

        let setData = {};

        Object.keys(usersLogin).map(k =>{
            setData[k]= usersLogin[k];
            if(k === 'birth_date' && usersLogin[k]){
                setData['bod'] = new Date(usersLogin[k]);
            }
            return k;
        });

        this.setState({
            loading: true,
            id: id,
            user: usersLogin,
            ...setData
        },()=>{
            this.setState({
                loading: false
            });
        });

    };

    UNSAFE_componentWillReceiveProps() {

        let lang = AuthHelper.getLang();

        this.validator = new SimpleReactValidator(
            {
                locale: lang
            }
        );
    }

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    enableUpdate = ()=>{
      this.setState({
          disableUpdate: false,
          error:{},
      });

    };

    handleClickShowPassword = () => {

        this.setState({

            showPassword:(!this.state.showPassword)

        });
    };

    handleClickShowPasswordConfirm = () => {

        this.setState({

            showPasswordConfirm:(!this.state.showPasswordConfirm)

        });
    };

    handleGo  = (link) => {

        history.push(link);
    };

    handleDate (date, prop){

        this.setState({

            [prop]: date

        })

    };

    handleChecked = name => e => {

        this.setState({

            [name]: e.target.checked ? true : false,

        })

    };

    handleSubmit = (e) => {

        e.preventDefault();

        if (!this.validator.allValid()) {

            this.setState({
                    errors: this.validator.getErrorMessages()
                }
            );

            return false;

        }

        if (this.state.password !== this.state.passwordConfirm) {

            this.setState({
                    errors: {
                        password: 'Kata sandi harus sama',
                        passwordConfirm: 'Konfirmasi kata sandi harus sama',
                    }
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true
            }
        );


        let params = {
            nip: this.state.nip,
            name: this.state.name,
            gender:this.state.gender,
            address:this.state.address,
            phone: this.state.phone,
            birth_place: this.state.birth_place,
            birth_date: this.state.bod !== null ? this.state.bod.getFullYear() + '-' + appendLeadingZeroes(this.state.bod.getMonth() + 1) + '-' + appendLeadingZeroes(this.state.bod.getDate()): '',
            age: '',
            religion: this.state.religion,
            citizenship: this.state.citizenship,
            tribes: this.state.tribes,

            username: this.state.username,
            password: this.state.password,
            access: this.state.access,
            role: this.state.role,
        };

        Api.put('/users/me/'+this.state.id,params).then(resp => {

            if(resp){
                AuthHelper.setProfile(resp);
            }

            this.setState({
                loadingButton: false,
            });

            this.handleGo('/');
            this.showMessage(true, 'Account successfully updated!');

        }).catch(err => {

            let errors = {};
            Object.keys(err).map((item) => {
                if(item){
                    errors[item] = err[item][0] ? err[item][0] : err[item];
                }
                return item;
            });

            this.setState({
                    errors: errors ? errors : {},
                    loadingButton: false
                }
            );

            this.showMessage(false, err.message);

        });


    };

    render() {

        let lang = {};
        if(this.props.toggle_set_lang === 'indo') {
            lang = languange.id;
        }else {
            lang = languange.en;
        }
        const text = lang;

        return (
            <div className='dashboard-detail'>
                <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb'>
                        <li className='breadcrumb-item'><Link to='/' >{text.label_dashboard}</Link></li>
                        <li className='breadcrumb-item active' aria-current='page'>{this.state.disableUpdate ? text.label_detail : text.button_edit}</li>
                    </ol>
                </nav>

                <div className='row'>
                    {/*<div className='col-lg-8 col-xl-7'>*/}
                    <div className='col-lg-12 col-xl-12'>
                        <div className='card-white'>
                            <h2 className='text-uppercase'>{text.label_my_profile}</h2>
                            { this.state.loading === true ? <i className='fa fa-spinner fa-spin' /> :
                                <form name='view' id='viewUsers' className='col-12 row' noValidate>
                                    <div className='col-6'>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='nip'
                                                name='nip'
                                                label={text.label_nip}
                                                placeholder={text.label_nip}
                                                onChange={(e) => this.handleChange(e, 'nip')}
                                                value={this.state.nip}
                                                fullWidth
                                                disabled
                                            />
                                            {this.validator.message('nip', this.state.nip, 'required')}
                                            <div className='text-danger'>{this.state.errors.nip}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='name'
                                                name='name'
                                                label={text.label_name}
                                                placeholder={text.label_name}
                                                onChange={(e) => this.handleChange(e, 'name')}
                                                value={this.state.name}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            />
                                            {this.validator.message('name', this.state.name, 'required')}
                                            <div className='text-danger'>{this.state.errors.name}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                select
                                                id='gender'
                                                name='gender'
                                                label={text.label_gender}
                                                onChange={(e) => this.handleChange(e, 'gender')}
                                                value={this.state.gender}
                                                fullWidth
                                                disabled
                                            >
                                                {this.state.listGender.map((data, idl) => (
                                                    <MenuItem key={idl} value={data.value}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {this.validator.message('gender', this.state.gender, 'required')}
                                            <div className='text-danger'>{this.state.errors.gender}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='address'
                                                name='address'
                                                label={text.label_address}
                                                placeholder={text.label_address}
                                                onChange={(e) => this.handleChange(e, 'address')}
                                                value={this.state.address}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                                multiline
                                                rows={4}
                                            >
                                            </TextField>
                                            {this.validator.message('address', this.state.address, 'required')}
                                            <div className='text-danger'>{this.state.errors.address}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='phone'
                                                name='phone'
                                                label={text.label_phone}
                                                placeholder={text.label_phone}
                                                onChange={(e) => this.handleChange(e, 'phone')}
                                                value={this.state.phone}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            />
                                            {/*{this.validator.message('phone', this.state.phone, 'required')}*/}
                                            <div
                                                className='text-danger'>{this.state.errors.phone && this.state.errors.phone}</div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-md-6'>
                                                <TextField
                                                    type='text'
                                                    id='birth_place'
                                                    name='birth_place'
                                                    label={text.label_birth_place}
                                                    placeholder={text.label_birth_place}
                                                    onChange={(e) => this.handleChange(e, 'birth_place')}
                                                    value={this.state.birth_place}
                                                    fullWidth
                                                    disabled={this.state.disableUpdate}
                                                />
                                                {/*{this.validator.message('phone', this.state.phone, 'required')}*/}
                                                <div
                                                    className='text-danger'>{this.state.errors.birth_place && this.state.errors.birth_place}</div>
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} className="col-md-12">
                                                    <DatePicker
                                                        className='col-12'
                                                        label={text.label_birth_date}
                                                        value={this.state.bod}
                                                        onChange={(date) => this.handleDate(date, 'bod')}
                                                        format={'yyyy-MM-dd'}
                                                        cancelLabel={text.button_back}
                                                        // inputVariant=""
                                                        size='small'
                                                        disabled={this.state.disableUpdate}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div className='text-danger'>{this.state.errors.bod}</div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                id='religion'
                                                name='religion'
                                                label={text.label_religion}
                                                placeholder={text.label_religion}
                                                onChange={(e) => this.handleChange(e, 'religion')}
                                                value={this.state.religion}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            >
                                            </TextField>
                                            {/*{this.validator.message('religion', this.state.religion, 'required')}*/}
                                            <div className='text-danger'>{this.state.errors.religion}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                id='citizenship'
                                                name='citizenship'
                                                label={text.label_citizenship}
                                                placeholder={text.label_citizenship}
                                                onChange={(e) => this.handleChange(e, 'citizenship')}
                                                value={this.state.citizenship}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            >
                                            </TextField>
                                            {/*{this.validator.message('citizenship', this.state.citizenship, 'required')}*/}
                                            <div className='text-danger'>{this.state.errors.citizenship}</div>
                                        </div>
                                        <div className='form-group'>
                                            <TextField
                                                id='tribes'
                                                name='tribes'
                                                label={text.label_tribes}
                                                placeholder={text.label_tribes}
                                                onChange={(e) => this.handleChange(e, 'tribes')}
                                                value={this.state.tribes}
                                                fullWidth
                                                disabled={this.state.disableUpdate}
                                            >
                                            </TextField>
                                            {/*{this.validator.message('tribes', this.state.tribes, 'required')}*/}
                                            <div className='text-danger'>{this.state.errors.tribes}</div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='form-group'>
                                            <TextField
                                                type='text'
                                                id='username'
                                                name='username'
                                                label={text.label_username}
                                                placeholder={text.label_username}
                                                onChange={(e) => this.handleChange(e, 'username')}
                                                value={this.state.username}
                                                fullWidth
                                                disabled
                                            />
                                            {this.validator.message('username', this.state.username, 'required')}
                                            <div className='text-danger'>{this.state.errors.username}</div>
                                        </div>

                                        {this.state.disableUpdate === false &&
                                            <div className='form-group'>
                                                <TextField
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    id='adornment-password'
                                                    name='password'
                                                    label={text.label_password}
                                                    placeholder={text.label_password}
                                                    onChange={(e) => this.handleChange(e, 'password')}
                                                    value={this.state.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <IconButton
                                                                    aria-label='Toggle password visibility'
                                                                    onClick={this.handleClickShowPassword}
                                                                    className='pass'
                                                                >
                                                                    {this.state.showPassword ?
                                                                        <i className='fas fa-eye'> </i> :
                                                                        <i className='fas fa-eye-slash'> </i>}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                />
                                                {/*{this.validator.message('password', this.state.password, 'required')}*/}
                                                <div className='text-danger'>{this.state.errors.password}</div>
                                            </div>
                                        }
                                        {this.state.disableUpdate === false &&
                                            <div className='form-group'>
                                                <TextField
                                                type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                                id='adornment-password'
                                                name='passwordConfirm'
                                                label={text.label_password_confirm}
                                                placeholder={text.label_password_confirm}
                                                onChange={(e) => this.handleChange(e, 'passwordConfirm')}
                                                value={this.state.passwordConfirm}
                                                InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={this.handleClickShowPasswordConfirm}
                                                            className='pass'
                                                        >
                                                            {this.state.showPasswordConfirm ?
                                                                <i className='fas fa-eye'> </i> :
                                                                <i className='fas fa-eye-slash'> </i>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                                fullWidth
                                                />
                                                {this.state.password &&
                                                    (
                                                        this
                                                        .validator.message('passwordConfirm', this.state.passwordConfirm, 'required')
                                                    )
                                                }
                                                <div className='text-danger'>{this.state.errors.passwordConfirm}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-12 text-center mt-4'>
                                        <Button
                                            variant='contained'
                                            className='mr-3'
                                            onClick={() => this.handleGo('/')}
                                        >
                                            {text.button_back}
                                        </Button>

                                        {this.state.disableUpdate ?
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className=''
                                                onClick={this.enableUpdate}
                                                disabled={this.state.loadingButton}
                                            >
                                                {text.button_edit} {this.state.loadingButton &&
                                            <i className='fa fa-spinner fa-spin'> </i>}
                                            </Button>
                                            :
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className=''
                                                onClick={this.handleSubmit}
                                                disabled={this.state.loadingButton}
                                            >
                                                {text.label_save} {this.state.loadingButton &&
                                            <i className='fa fa-spinner fa-spin'> </i>}
                                            </Button>
                                        }
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        );
    }
}


const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        toggle_set_lang: state.toggle_set_lang,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDetail);


function appendLeadingZeroes(n) {
    if (n <= 9) {
        return "0" + n;
    }
    return n
}