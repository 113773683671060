import React from 'react';
import TextField from "@material-ui/core/TextField";
import {MultipleUpload} from "./multipleUpload";

export const MultipleAnswers = ({
    // data to upload image
    keysImg,
    id,
    text,
    imageFile,
    onFileChange,
    handleUpload,
    handleDeleteUpload,
    // disableBtn,

    // data options
    typeOptions,
    listOptions,// listArray
    handleChangeAnswer,
    handlePlus,
    handleMinus
}) => (
    <>
    {typeOptions &&
        // === 'radio' || typeOptions === 'checkbox' || typeOptions === true) &&
        <div className='form-group'>
            { listOptions.map((item,idx) => (
                <div className='row border-bottom m-0' key={idx}>
                    {(listOptions.length -1 === idx) ?
                        <div className='form-group col-9 m-0 p-0 py-2 text-center'>
                            <TextField
                                type='text'
                                id={'label_' + idx}
                                name={'label_' + idx}
                                label={text.label_answer_title}
                                placeholder={text.label_answer_title}
                                onChange={(e) => handleChangeAnswer(e, 'label', idx)}
                                value={item.label}
                                fullWidth
                            />
                        </div> :<>
                            <div className='form-group col m-0 py-2 p-0'>
                                {text.label_answer_title}:
                            </div>
                            <div className='form-group col m-0 py-2 p-0'>
                                { item.label}
                            </div></>
                    }
                    <div className='form-group col-3 m-0 py-2 text-center'>
                        {(listOptions.length -1 === idx) &&
                        <button className='btn btn-info'
                                onClick={() => handlePlus(idx)}>+</button>
                        }
                        <button className='btn btn-secondary'
                                onClick={() => handleMinus(idx)}>-</button>
                    </div>
                    {(listOptions.length -1 === idx) ?
                        <div className='form-group col-12 m-0 p-0 py-2 mb-2'>
                            <TextField
                                type='text'
                                id={'answer_' + idx}
                                name={'answer_' + idx}
                                label={text.label_answer_subject}
                                placeholder={text.label_answer_subject}
                                onChange={(e) => handleChangeAnswer(e, 'answer', idx)}
                                value={item.answer}
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </div> :<>
                            <div className='form-group col-12 m-0 py-2 p-0'>
                                {text.label_answer_subject}:
                            </div>
                            <div className='form-group col-12 m-0 py-2 p-0'>
                                { item.answer}
                            </div></>
                    }
                    <MultipleUpload
                        onFileChange={onFileChange}
                        handleUpload={handleUpload}
                        keys={keysImg+idx}
                        id={keysImg+idx}
                        text={text}
                        imagePut={imageFile}
                        handleDeleteUpload={handleDeleteUpload}
                        disableBtn={listOptions.length -1 === idx ? false : true}
                    />

                </div>
            ))}

        </div>
    }
    </>
);