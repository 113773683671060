import React from 'react';
import TextField from "@material-ui/core/TextField";

export const MultipleQuestions = ({
    text,
    view,
    // data options
    typeOptions,
    listOptions,// listArray
    handleChangeAnswer,
    handlePlus,
    handleMinus
}) => (
    <>
    {typeOptions &&
        <div className='form-group'>
            { listOptions.map((item,idx) => (
                <div className='row border-bottom m-0' key={idx}>
                    <div className='form-group col-9 p-0 m-0 py-2 text-left'>
                        {view === 'Table' ? text.label_questions1+' @ '+(idx+1) : text.label_questions}
                    </div>
                    <div className='form-group col-3 m-0 p-0 py-2 text-center'>
                        {(listOptions.length -1 === idx) &&
                        <button className='btn btn-info'
                                onClick={() => handlePlus(idx,'question')}>+</button>
                        }
                        <button className='btn btn-secondary'
                                onClick={() => handleMinus(idx,'question')}>-</button>
                    </div>
                    {(listOptions.length -1 === idx) ?
                        <div className='form-group col-12 m-0 p-0 py-2 text-center'>
                            <TextField
                                type='text'
                                id={'question_' + idx}
                                name={'question_' + idx}
                                onChange={(e) => handleChangeAnswer(e, 'question', idx, true)}
                                value={item.question}
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </div> :<div className='form-group col-12 m-0 p-0 py-2 text-left'>
                            { item.question}
                        </div>
                    }

                </div>
            ))}

        </div>
    }
    </>
);